import {
    TRYING_ADD_COMPANY_EMPLOYEES,
    TRYING_ADD_COMPANY_EMPLOYEES_SUCCESS,
    TRYING_ADD_COMPANY_EMPLOYEES_ERROR,
    
    TRYING_DELETE_COMPANY_EMPLOYEES,
    TRYING_DELETE_COMPANY_EMPLOYEES_SUCCESS,
    TRYING_DELETE_COMPANY_EMPLOYEES_ERROR,
    
    TRYING_DOWNLOAD_EMPLOYEES,
    TRYING_DOWNLOAD_EMPLOYEES_SUCCESS,

    TRYING_INSERTEDIT_COMPANY,
    TRYING_INSERTEDIT_COMPANY_SUCCESS,
    TRYING_INSERTEDIT_COMPANY_ERROR,

    TRYING_GET_COMPANIES,
    TRYING_GET_COMPANIES_SUCCESS,
    TRYING_GET_COMPANIES_ERROR,

    TRYING_GET_COMPANY,
    TRYING_GET_COMPANY_SUCCESS,
    TRYING_GET_COMPANY_ERROR
} from '../Types';

const INITIAL_STATE = {
    TryingAddCompanyEmployees: false,
    TryingAddCompanyEmployeesError: null,
    TryingDeleteCompanyEmployees: false,
    TryingDeleteCompanyEmployeesError: null,
    TryingDownloadEmployees: false,
    TryingDownloadEmployeesError: null,
    TryingGetCompanies: false,
    TryingGetCompaniesError: null,
    TryingGetCompany: false,
    TryingGetCompanyError: null,
    TryingInsertEditCompany: false,
    TryingInsertEditCompanyError: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRYING_ADD_COMPANY_EMPLOYEES:
            return { ...state, TryingAddCompanyEmployees: true, TryingAddCompanyEmployeesError: null };
        case TRYING_ADD_COMPANY_EMPLOYEES_ERROR:
            return { ...state, TryingAddCompanyEmployees: false, TryingAddCompanyEmployeesError: action.payload };
        case TRYING_ADD_COMPANY_EMPLOYEES_SUCCESS:
            return { ...state, TryingAddCompanyEmployees: false, TryingAddCompanyEmployeesError: null };

        case TRYING_DELETE_COMPANY_EMPLOYEES:
            return { ...state, TryingDeleteCompanyEmployees: true, TryingDeleteCompanyEmployeesError: null };
        case TRYING_DELETE_COMPANY_EMPLOYEES_ERROR:
            return { ...state, TryingDeleteCompanyEmployees: false, TryingDeleteCompanyEmployeesError: action.payload };
        case TRYING_DELETE_COMPANY_EMPLOYEES_SUCCESS:
            return { ...state, TryingDeleteCompanyEmployees: false, TryingDeleteCompanyEmployeesError: null };

        case TRYING_DOWNLOAD_EMPLOYEES:
            return { ...state, TryingDownloadEmployees: true, TryingDownloadEmployeesError: null };
        case TRYING_DOWNLOAD_EMPLOYEES_SUCCESS:
            return { ...state, TryingDownloadEmployees: false, TryingDownloadEmployeesError: null };

        case TRYING_GET_COMPANIES:
            return { ...state, TryingGetCompanies: true, TryingGetCompaniesError: null };
        case TRYING_GET_COMPANIES_ERROR:
            return { ...state, TryingGetCompanies: false, TryingGetCompaniesError: action.payload };
        case TRYING_GET_COMPANIES_SUCCESS:
            return { ...state, TryingGetCompanies: false, TryingGetCompaniesError: null };

        case TRYING_GET_COMPANY:
            return { ...state, TryingGetCompany: true, TryingGetCompanyError: null };
        case TRYING_GET_COMPANY_ERROR:
            return { ...state, TryingGetCompany: false, TryingGetCompanyError: action.payload };
        case TRYING_GET_COMPANY_SUCCESS:
            return { ...state, TryingGetCompany: false, TryingGetCompanyError: null };

        case TRYING_INSERTEDIT_COMPANY:
            return { ...state, TryingInsertEditCompany: true, TryingInsertEditCompanyError: null };
        case TRYING_INSERTEDIT_COMPANY_ERROR:
            return { ...state, TryingInsertEditCompany: false, TryingInsertEditCompanyError: action.payload };
        case TRYING_INSERTEDIT_COMPANY_SUCCESS:
            return { ...state, TryingInsertEditCompany: false, TryingInsertEditCompanyError: null };

        default:
            return state;
    }
};