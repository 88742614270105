import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { AddClientJournalEntry, GetClientJournalEntries, UploadToPresignedUrl } from '../../Services/Actions';
import moment from 'moment';

import * as styles from './styles';

import ButtonGroup from '../../Components/Buttons/ButtonGroup';
import Loading from '../../Components/Loading';
import MultiText from '../../Components/Text/MultiText';
import PageText from '../../Components/Text/PageText';
import PaginationContainer from '../../Components/PaginationCounter';
import Spacer from '../../Components/Spacer';
import TextArea from '../../Components/Form/TextArea';
import UploadProgressComponent from '../../Components/UploadProgress';

import PreviewMediaModal from '../../Modals/PreviewMedia';

import Camera from '../../Assets/Icons/Camera.png';
import PDF from '../../Assets/Icons/PDF.png';
import Plus from '../../Assets/Icons/PlusSign_White.png';

import { CreateUploadToPresignedUrlPromise, getFileExtension } from '../../Functions';

class ClientJournal extends React.PureComponent {
    _isMounted = false;

    state = {
        IsMoreResults: 0,
        JournalEntries: [],
        TotalPages: 0,
        TotalRecords: 0,

        PageNo: 1,
        PageSize: 10,

        ShowAddJournalEntry: false,

        PreviewMedia: null,
        PreviewMediaFileType: null,
        PreviewMediaUrl: '',
        ShowFullscreenMediaPreview: false,

        Cancelled: null,
        DiscussedFrequency: null,
        FrequencyRecommendation: '',
        IsVideoCall: null,
        JournalEntry: '',
        NotStarted: null,
        VideoCallDuration: 0,
        UploadMedia: [],

        MaxFileSizesKB: 102400,
        MediaTooLarge: false,
        SelectedMedia: [],
        TotalUsedSize: 0
    };

    componentDidMount() {
        this._isMounted = true;

        this.onLoadJournalEntries();
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }

    onChangeCancelled = Cancelled => {
        this.setState({ Cancelled });
    }

    onChangeDiscussedFrequency = DiscussedFrequency => {
        this.setState({ DiscussedFrequency });
    }

    onChangeFrequencyRecommendation = FrequencyRecommendation => {
        this.setState({ FrequencyRecommendation });
    }

    onChangeIsVideoCall = IsVideoCall => {
        this.setState({ IsVideoCall });
    }

    onChangeJournalEntry = event => {
        this.setState({ JournalEntry: event.target.value });
    }

    onChangeNotStarted = NotStarted => {
        this.setState({ NotStarted });
    }

    onChangeVideoCallDuration = event => {
        this.setState({ VideoCallDuration: event.target.value });
    }

    onDeleteMedia = ({ event, FileSize_KB, MediaUrl }) => {
        if (event) event.preventDefault();

        FileSize_KB = Math.round((this.state.TotalUsedSize - FileSize_KB) * 10) / 10;

        for (var i = 0; i < this.state.SelectedMedia.length; i++) {
            if (this.state.SelectedMedia[i].MediaUrl === MediaUrl) {
                var SelectedIndex = i;
                break;
            }
        }

        this.setState({
            MediaTooLarge: 0,
            SelectedMedia: [ ...this.state.SelectedMedia.slice(0, SelectedIndex), ...this.state.SelectedMedia.slice(SelectedIndex + 1) ],
            TotalUsedSize: FileSize_KB,
            UploadMedia: [ ...this.state.UploadMedia.slice(0, SelectedIndex), ...this.state.UploadMedia.slice(SelectedIndex + 1) ]
        });
    }

    onInsertJournalEntry = () => {
        var { ClientId } = this.props;
        var { Cancelled, DiscussedFrequency, FrequencyRecommendation, IsVideoCall, JournalEntry, NotStarted, UploadMedia, VideoCallDuration } = this.state;

        var JournalMedia = JSON.stringify(UploadMedia.map(({ name: FileName, size: FileSize, type: ContentType }) => ({ ContentType, FileName, FileSize })));

        this.props.AddClientJournalEntry({ Cancelled, ClientId, DiscussedFrequency, FrequencyRecommendation, IsVideoCall, JournalEntry, JournalMedia, NotStarted, VideoCallDuration }).then(({ PreSignedUrls }) => {
            if (!this.props.TryingInsertEditTrainerClientMediaError) {
                if (UploadMedia.length > 0 && PreSignedUrls.length > 0) {
                    var UploadFunction = this.props.UploadToPresignedUrl;
                    var Promises = PreSignedUrls.map((PreSignedUrl, index) => CreateUploadToPresignedUrlPromise({ ContentType: 'ClientJournalEntry', File: UploadMedia[index], PresignedFormData: PreSignedUrl, UploadFunction }));

                    Promise.all(Promises).then(() => {
                        this.setState({ Cancelled: null, DiscussedFrequency: null, VideoCallDuration: 0, FrequencyRecommendation: '', IsVideoCall: null, JournalEntry: '', NotStarted: null, PageNo: 1, ShowAddJournalEntry: '', SelectedMedia: [], UploadMedia: [] }, () => this.onLoadJournalEntries());
                    });   
                }
                else this.setState({ Cancelled: null, DiscussedFrequency: null, VideoCallDuration: 0, FrequencyRecommendation: '', IsVideoCall: null, JournalEntry: '', NotStarted: null, PageNo: 1, ShowAddJournalEntry: '', SelectedMedia: [], UploadMedia: [] }, () => this.onLoadJournalEntries());
            }
        });
    }

    onLoadJournalEntries = Increment => {
        var { ClientId } = this.props;
        var { PageNo, PageSize  } = this.state;

        if (Increment) PageNo++;

        this.props.GetClientJournalEntries({ ClientId, PageNo, PageSize }).then(({ IsMoreResults, JournalEntries, TotalPages, TotalRecords }) => {
            // var TotalPages = Math.floor(TotalRecords / PageSize) + 1;
            this.setState({ IsMoreResults, JournalEntries, TotalPages, TotalRecords });
        });
    }

    onPaginate = ({ NewPageNo }) => {
        if (this.statePageNo !== NewPageNo) this.setState({ PageNo: NewPageNo }, () => this.onLoadJournalEntries());
    }

    onSelectUploadMedia = ({ event }) => {
        var TotalUsedSize = this.state.TotalUsedSize;
        var Files = event.target.files;
        var NumFiles = Files.length;

        var i;

        if (NumFiles > 0) {
            for (i = 0; i < NumFiles; i++) {
                TotalUsedSize += Math.round((Files[i].size / 1000) * 10) / 10;
            }

            if (TotalUsedSize > this.state.MaxFileSizesKB) this.setState({ MediaTooLarge: TotalUsedSize });
            else {
                var SelectedMedia = [];

                for (i = 0; i < NumFiles; i++) {
                    // console.log('Files[i]: ', Files[i]);
                    SelectedMedia.push({ FileExtension: getFileExtension(Files[i].name), FileName: Files[i].name, FileSize_KB: Math.round((Files[i].size / 1000) * 10) / 10, IsNewMedia: true, MediaUrl: URL.createObjectURL(Files[i]) });
                }

                this.setState({ MediaTooLarge: 0, UploadMedia: [ ...this.state.UploadMedia, ...Files ], SelectedMedia: [ ...this.state.SelectedMedia, ...SelectedMedia ], TotalUsedSize });
            }

            document.getElementById('UploadMedia').value = null;
        }
    }

    onToggleShowAddJournalEntry = ({ ShowAddJournalEntry }) => {
        if (!ShowAddJournalEntry) this.setState({ ShowAddJournalEntry, IsVideoCall: null, JournalEntry: '', MediaTooLarge: false, SelectedMedia: [], TotalUsedSize: 0, UploadMedia: [] });
        else this.setState({ ShowAddJournalEntry });
    }

    onToggleShowFullscreenMediaPreview = (PreviewMedia, PreviewMediaUrl, ShowFullscreenMediaPreview, PreviewMediaFileType) => {
        if (this._isMounted) this.setState({ PreviewMedia, PreviewMediaUrl, ShowFullscreenMediaPreview, PreviewMediaFileType });
    }

    renderAddJournalEntry = () => {
        var { t } = this.props;
        var { UploadProgress } = this.props;
        var { Cancelled, DiscussedFrequency, FrequencyRecommendation, IsVideoCall, JournalEntry, NotStarted, VideoCallDuration } = this.state;

        var Disabled = (
            IsVideoCall === null ||
            (!!IsVideoCall && !+VideoCallDuration) ||
            !JournalEntry ||
            DiscussedFrequency === null ||
            FrequencyRecommendation === ''
        );

        var Buttons = [];
        Buttons.push({ BackgroundColor: 'white-concrete-hover', BackgroundColorHover: 'gray-dark', Border: 'none', Color: 'white', ColorHover: 'white', FontFamily: 'semibold', OnClick: () => this.onToggleShowAddJournalEntry({ ShowAddJournalEntry: false }), Title: t('_cancel') });
        Buttons.push({ BackgroundColor: 'blue-abel', BackgroundColorHover: 'blue-abel-hover', Border: 'none', Color: 'white', ColorHover: 'white', Disabled, FontFamily: 'semibold', OnClick: () => this.onInsertJournalEntry(), Title: t('_save') });

        return (
            <styles.AddJournalEntryContainer className="AddJournalEntryContainer">
                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="center" NoMargin Text={t('add_journal_entry_title')} TextAlign="center" />

                <Spacer Size="small" />

                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="center" NoMargin Text={t('add_journal_entry_not_editable_warning')} TextAlign="center" />

                <Spacer Size="small" />

                {/* Journal Entry Text */}
                    <MultiText
                        TextAlign="left"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-2', Text: t('journal_entry') },
                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-2', Text: '*' },
                        ]}
                    />

                    <Spacer Size="small" />

                    <TextArea
                        OnBlur={this.onChangeJournalEntry}
                        Placeholder={t('ptadmin_clientnote_placeholder')}
                        Value={JournalEntry}
                    />

                {/* Is Video Call */}
                    <MultiText
                        TextAlign="left"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('is_video_call') },
                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-1', Text: '*' },
                        ]}
                    />

                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeIsVideoCall(1)} Selected={IsVideoCall === 1} />

                        <styles.RadioButtonPageText ElementType="span" FontFamily={IsVideoCall === 1 ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeIsVideoCall(1)} Text={t('_yes')} TextAlign="left" />
                    </styles.RadioButtonParentContainer>

                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeIsVideoCall(0)} Selected={IsVideoCall === 0} />

                        <styles.RadioButtonPageText ElementType="span" FontFamily={IsVideoCall === 0 ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeIsVideoCall(0)} Text={t('_no')} TextAlign="left" />
                    </styles.RadioButtonParentContainer>

                    {/* If Video Call, Show Duration */}
                    {
                        !!+IsVideoCall &&
                        <>
                            <Spacer Size="extra-extra-small" />

                            <MultiText
                                TextAlign="left"
                                Texts={[
                                    { FontFamily: 'medium', FontSize: 'medium-1', Text: t('WorkoutPlan_duration_on_minutes') },
                                    { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-1', Text: '*' },
                                ]}
                            />

                            <styles.StyledDuration
                                NoDecimal
                                NoLabel
                                NoMargin
                                OnChange={this.onChangeVideoCallDuration}
                                Placeholder='0'
                                Size="medium"
                                Type="number"
                                Value={VideoCallDuration}
                            />
                        </>
                    }

                <Spacer Size="small" />

                {/* Discussed Frequency */}
                    <MultiText
                        TextAlign="left"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('discussed_frequency') },
                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-1', Text: '*' },
                        ]}
                    />

                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeDiscussedFrequency(1)} Selected={DiscussedFrequency === 1} />

                        <styles.RadioButtonPageText ElementType="span" FontFamily={DiscussedFrequency === 1 ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeDiscussedFrequency(1)} Text={t('_yes')} TextAlign="left" />
                    </styles.RadioButtonParentContainer>

                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeDiscussedFrequency(0)} Selected={DiscussedFrequency === 0} />

                        <styles.RadioButtonPageText ElementType="span" FontFamily={DiscussedFrequency === 0 ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeDiscussedFrequency(0)} Text={t('_no')} TextAlign="left" />
                    </styles.RadioButtonParentContainer>

                <Spacer Size="small" />

                {/* Frequency Recommendation */}
                    <MultiText
                        TextAlign="left"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('frequency_recommendation') },
                            { FontColor: 'red-bittersweet', FontFamily: 'medium', FontSize: 'medium-1', Text: '*' },
                        ]}
                    />

                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeFrequencyRecommendation('weekly')} Selected={FrequencyRecommendation === 'weekly'} />

                        <styles.RadioButtonPageText ElementType="span" FontFamily={FrequencyRecommendation === 'weekly' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeFrequencyRecommendation('weekly')} Text="weekly" TextAlign="left" />
                    </styles.RadioButtonParentContainer>

                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeFrequencyRecommendation('bi-weekly')} Selected={FrequencyRecommendation === 'bi-weekly'} />

                        <styles.RadioButtonPageText ElementType="span" FontFamily={FrequencyRecommendation === 'bi-weekly' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeFrequencyRecommendation('bi-weekly')} Text="bi-weekly" TextAlign="left" />
                    </styles.RadioButtonParentContainer>

                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeFrequencyRecommendation('monthly')} Selected={FrequencyRecommendation === 'monthly'} />

                        <styles.RadioButtonPageText ElementType="span" FontFamily={FrequencyRecommendation === 'monthly' ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeFrequencyRecommendation('monthly')} Text="monthly" TextAlign="left" />
                    </styles.RadioButtonParentContainer>

                    <Spacer Size="small" />

                {/* Cancelled */}
                    <MultiText
                        TextAlign="left"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('cancelled_journalentry') },
                        ]}
                    />

                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeCancelled(1)} Selected={Cancelled === 1} />

                        <styles.RadioButtonPageText ElementType="span" FontFamily={Cancelled === 1 ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeCancelled(1)} Text={t('_yes')} TextAlign="left" />
                    </styles.RadioButtonParentContainer>

                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeCancelled(0)} Selected={Cancelled === 0} />

                        <styles.RadioButtonPageText ElementType="span" FontFamily={Cancelled === 0 ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeCancelled(0)} Text={t('_no')} TextAlign="left" />
                    </styles.RadioButtonParentContainer>


                <Spacer Size="small" />

                {/* Not Started */}
                    <MultiText
                        TextAlign="left"
                        Texts={[
                            { FontFamily: 'medium', FontSize: 'medium-1', Text: t('not_started') },
                        ]}
                    />

                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeNotStarted(1)} Selected={NotStarted === 1} />

                        <styles.RadioButtonPageText ElementType="span" FontFamily={NotStarted === 1 ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeNotStarted(1)} Text={t('_yes')} TextAlign="left" />
                    </styles.RadioButtonParentContainer>

                    <styles.RadioButtonParentContainer className="RadioButtonParentContainer">
                        <styles.PseudoCheckbox className="PseudoCheckbox" BackgroundColor="274775" onClick={() => this.onChangeNotStarted(0)} Selected={NotStarted === 0} />

                        <styles.RadioButtonPageText ElementType="span" FontFamily={NotStarted === 0 ? 'semibold' : 'medium'} FontSize="medium-1" JustifyContent="flex-start" OnClick={() => this.onChangeNotStarted(0)} Text={t('_no')} TextAlign="left" />
                    </styles.RadioButtonParentContainer>


                <Spacer Size="small" />

                {/* Upload Media */}
                {this.renderUploadMedia()}
                    
                <Spacer Size="medium" />

                <ButtonGroup
                    Buttons={Buttons}
                    ButtonWidth="fit-content"
                    NotTouching
                    OwnRows={false}
                />

                {
                    UploadProgress.ClientJournalEntry && Object.entries(UploadProgress.ClientJournalEntry).length > 0 && 
                        <>
                            <Spacer Size="medium" />

                            <UploadProgressComponent UploadingItems={UploadProgress.ClientJournalEntry} />
                        </>
                }
            </styles.AddJournalEntryContainer>
        );
    }

    renderJournalEntries = () => {
        var { t } = this.props;
        var { JournalEntries } = this.state;

        return (
            <styles.JournalEntriesContainer className="JournalEntriesContainer">
                {
                    JournalEntries.map(({ Id, Cancelled, CreatedDate, DiscussedFrequency, FrequencyRecommendation, IsVideoCall, JournalEntry, JournalMedia, NotStarted, VideoCallDuration }) => {
                        return (
                            <styles.JournalEntryContainer className="JournalEntryContainer" key={Id}>
                                <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={moment(CreatedDate).format('[TrackingHistory]')} TextAlign="left" />

                                {
                                    !!+IsVideoCall &&
                                    <>
                                        <Spacer Size="extra-extra-small" />

                                        {/* eslint-disable-next-line */}
                                        <styles.JournalEntryVideoCall role="img" aria-label="check">{t('video_call')} &#x2705;</styles.JournalEntryVideoCall>

                                        <Spacer Size="extra-extra-small" />

                                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('WorkoutPlan_duration_on_minutes')}: ${VideoCallDuration}`} TextAlign="left" />
                                    </>
                                }

                                {
                                    !!+Cancelled &&
                                    <>
                                        <Spacer Size="extra-extra-small" />

                                        {/* eslint-disable-next-line */}
                                        <styles.JournalEntryVideoCall role="img" aria-label="check">{t('cancelled_journalentry')} &#x2705;</styles.JournalEntryVideoCall>
                                    </>
                                }

                                {
                                    !!+NotStarted &&
                                    <>
                                        <Spacer Size="extra-extra-small" />

                                        {/* eslint-disable-next-line */}
                                        <styles.JournalEntryVideoCall role="img" aria-label="check">{t('not_started')} &#x2705;</styles.JournalEntryVideoCall>
                                    </>
                                }

                                {/* Discussed Frequency */}
                                    <Spacer Size="extra-extra-small" />

                                    {
                                        !!DiscussedFrequency ?
                                        <>
                                            {/* eslint-disable-next-line */}
                                            <styles.JournalEntryVideoCall role="img" aria-label="check">{t('discussed_frequency')} &#x2705;</styles.JournalEntryVideoCall>
                                        </>
                                    :
                                        <>
                                            {/* eslint-disable-next-line */}
                                            <styles.JournalEntryVideoCall role="img" aria-label="x">{t('discussed_frequency')} &#x274C;</styles.JournalEntryVideoCall>
                                        </>
                                    }

                                {/* Frequency Recommendation */}
                                    <Spacer Size="extra-extra-small" />

                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${t('frequency_recommendation')}: ${FrequencyRecommendation}`} TextAlign="left" />


                                <Spacer Size="small" />

                                <PageText FontFamily="medium-italic" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={JournalEntry} TextAlign="left" WhiteSpace="pre-wrap" />

                                {
                                    JournalMedia.length > 0 &&
                                    <>
                                        <styles.StyledHR />

                                        <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" Text={t('uploaded_media')} TextAlign="left" />

                                        <styles.JournalEntryMediaParentContainer className="JournalEntryMediaParentContainer">
                                            <styles.JournalEntryMediaListContainer className="JournalEntryMediaListContainer">
                                                {
                                                    JournalMedia.map(({ JournalEntryMediaId, FileName, MediaUrl }) => {
                                                        return (
                                                            <React.Fragment key={JournalEntryMediaId}>
                                                                {this.renderMediaPreview({ Document: { FileName, MediaUrl }})}
                                                            </React.Fragment>
                                                        )
                                                    })
                                                }
                                            </styles.JournalEntryMediaListContainer>
                                        </styles.JournalEntryMediaParentContainer>
                                    </>
                                }
                            </styles.JournalEntryContainer>
                        );
                    })
                }
            </styles.JournalEntriesContainer>
        );
    }

    renderMediaPreview = ({ Document: { FileExtension, FileName, FileSize_KB, IsNewMedia, MediaUrl } }) => {
        FileExtension = FileExtension || getFileExtension(MediaUrl);
        IsNewMedia = IsNewMedia || false;

        switch (FileExtension) {
            case 'png':
            case 'jpg':
            case 'jpeg':
                return (
                    <styles.MediaPreview key={MediaUrl}>
                        <styles.StyledMediaPreview ImageSrc={MediaUrl} IsNewMedia={IsNewMedia} OnClick={() => this.onToggleShowFullscreenMediaPreview(null, MediaUrl, true)} SelfLoading />
                        {!!IsNewMedia && <styles.MultiSelect onClick={() => this.onDeleteMedia({ FileSize_KB, MediaUrl })} />}
                    </styles.MediaPreview>
                );

            case 'mov':
            case 'mp4':
                return (
                    <styles.MediaPreview key={MediaUrl}>
                        <styles.StyledVideoPreview IsNewMedia={IsNewMedia} autoPlay={false} controls={false} height="100%" width="100%" onClick={() => this.onToggleShowFullscreenMediaPreview(null, MediaUrl, true, 'video')} src={MediaUrl} />
                        {!!IsNewMedia && <styles.MultiSelect onClick={() => this.onDeleteMedia({ FileSize_KB, MediaUrl })} />}
                    </styles.MediaPreview>
                );

            case 'pdf':
                return (
                    <styles.StyledExternalLink key={MediaUrl} href={MediaUrl} target="_new">
                        <styles.StyledMediaDocumentPreview>
                            <styles.ButtonIcon src={PDF} alt="Button icon" />
                            <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FileName} TextAlign="left" />
                            {!!IsNewMedia && <styles.MultiSelect onClick={() => this.onDeleteMedia({ FileSize_KB, MediaUrl })} />}
                        </styles.StyledMediaDocumentPreview>
                    </styles.StyledExternalLink>
                );

            default:
                return (
                    <styles.StyledMediaDocumentPreview key={MediaUrl}>
                        <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={FileName} TextAlign="left" />
                        {!!IsNewMedia && <styles.MultiSelect onClick={() => this.onDeleteMedia({ FileSize_KB, MediaUrl })} />}
                    </styles.StyledMediaDocumentPreview>
                );
        }
    }

    renderPreviewFullscreenMedia = () => {
        var { PreviewMedia, PreviewMediaFileType, PreviewMediaUrl, ShowFullscreenMediaPreview } = this.state;

        if (PreviewMediaUrl && ShowFullscreenMediaPreview) {
            return (
                <PreviewMediaModal
                    FileType={PreviewMediaFileType}
                    Media={PreviewMedia}
                    MediaUrl={PreviewMediaUrl}
                    OnHide={() => this.onToggleShowFullscreenMediaPreview(null, null, false)}
                    Show={ShowFullscreenMediaPreview}
                />
            );
        }
    }

    renderUploadMedia = () => {
        var { t } = this.props;
        var { Device } = this.props;
        var { MaxFileSizesKB, MediaTooLarge, TotalUsedSize, SelectedMedia } = this.state;

        var Documents = [ ...SelectedMedia ]
        
        var Buttons = [
            { BackgroundColor: 'white', BackgroundColorHover: 'white-concrete', Color: 'black', ColorHover: 'black', FontFamily: 'semibold', Icon: Camera, IconPosition: 'left', IsInput: true, InputProps: { accept: "image/jpg,image/png,image/jpeg,.jpg,.png,.jpeg,video/mov,video/mp4,.mov,.mp4,application/pdf,.pdf", id: "UploadMedia", multiple: "multiple", type: "file", onChange: event => this.onSelectUploadMedia({ event }) }, Title: t('Upload') }
        ];

        MaxFileSizesKB = MaxFileSizesKB / 1024;

        return (
            <styles.UploadContainer>
                <styles.UploadButtonsRow>
                    <styles.UploadButtonsContainer
                        Buttons={Buttons}
                        ButtonWidth="fit-content"
                        ContainerWidth={(Device === 'mobile_small' || Device === 'mobile') ? '100%' : null}
                        NotTouching
                        OwnRows={Device === 'mobile_small' || Device === 'mobile'}
                    />
                </styles.UploadButtonsRow>
                
                <PageText FontFamily="medium" FontSize="medium-1" Text={`${(Math.round(((TotalUsedSize) / 1024) * 100) / 100)}MB / ${MaxFileSizesKB}MB ${t('used')}`} />
                
                {
                    !!+MediaTooLarge &&
                    <PageText
                        ContainerWidth="100%"
                        FontColor="red-bittersweet"
                        FontFamily="medium-italic"
                        FontSize="medium-1"
                        JustifyContent="center"
                        Text={t('file_too_large', { MaxUploadSize: `${MaxFileSizesKB}MB`, UploadedSize: `${(Math.round(((this.state.MediaTooLarge) / 1024) * 100) / 100)}MB` })}
                        TextAlign="center"
                    />
                }
                {
                    Documents.length > 0 &&
                    <styles.UploadDocumentsContainer>
                        {Documents.map(Document => this.renderMediaPreview({ Document }))}
                    </styles.UploadDocumentsContainer>
                }
            </styles.UploadContainer>
        );
    }

    renderViewCount = () => {
        var { t } = this.props;

        var { PageNo, PageSize, TotalRecords } = this.state;

        if (!TotalRecords) TotalRecords = 0;

        return (
            <div className="ViewCount">
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('items_count_viewing')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${!TotalRecords ? 0 : ((PageNo - 1) * PageSize) + 1} - ${(PageNo * PageSize) < TotalRecords ? (PageNo * PageSize) : TotalRecords}`} />
                &nbsp;
                <PageText ElementType="span" FontFamily="medium" FontSize="medium-1" Text={t('of')} />
                &nbsp;
                <PageText ElementType="span" FontFamily="semibold" FontSize="medium-1" Text={`${TotalRecords} ${t('journal_entries').toLowerCase()}`} />
            </div>
        )
    }

    render() {
        var { t } = this.props;
        var { PageNo, PageSize, ShowAddJournalEntry, TotalPages, TotalRecords } = this.state;

        return (
            <>
                {(this.props.TryingAddClientJournalEntry || this.props.TryingGetClientJournalEntries) && <Loading />}

                <PageText FontFamily="medium" FontSize="large" JustifyContent="center" NoMargin Text={t('ptadmin_contenttype_header_journal')} TextAlign="center" />

                <ButtonGroup
                    Buttons={[{ BackgroundGradient: "gradient1", Border: 'none', Color: 'white', ColorHover: 'white', Icon: Plus, IconPosition: 'left', IconSize: { Height: '20px', Width: '20px' }, OnClick: () => this.onToggleShowAddJournalEntry({ ShowAddJournalEntry: true }), Title: t('new_journal_entry') }]}
                    ButtonWidth="fit-content"
                    ContainerWidth="fit-content"
                    JustifyContent="center"
                />

                <Spacer Size="small" />

                {this.renderViewCount()}

                {
                    !!ShowAddJournalEntry &&
                    <>
                        <Spacer Size="small" />

                        {this.renderAddJournalEntry()}
                    </>
                }

                {
                    TotalRecords > 0 &&
                    <>
                        <Spacer Size="small" />

                        {
                            (PageNo !== 1 || PageSize < TotalRecords) &&
                            <>
                                <PaginationContainer OnPaginate={this.onPaginate} PageNo={PageNo} PageSize={PageSize} TotalPages={TotalPages} />
        
                                <Spacer Size="medium" />
                            </>
                        }

                        {this.renderJournalEntries()}
                    </>
                }

                {this.renderPreviewFullscreenMedia()}
            </>
        );
    }
}

ClientJournal.propTypes = {
    ClientId: PropTypes.string.isRequired
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        ClientDetails: state.Client.ClientDetails,

        UploadProgress: state.UploadContent.UploadProgress,

        TryingAddClientJournalEntry: state.Client.TryingAddClientJournalEntry,
        TryingAddClientJournalEntryError: state.Client.TryingAddClientJournalEntryError,
        TryingGetClientJournalEntries: state.Client.TryingGetClientJournalEntries,
        TryingGetClientJournalEntriesError: state.Client.TryingGetClientJournalEntriesError,
    };
};

export default withTranslation()(connect(mapStateToProps, { AddClientJournalEntry, GetClientJournalEntries, UploadToPresignedUrl } )(ClientJournal));