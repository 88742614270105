import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import history from '../../history';
import { GetMonthlyProducts } from '../../Services/Actions';

import Select, { components } from 'react-select';

import * as styles from './styles';

import Loading from '../../Components/Loading';
import Page from '../../Components/Page';
import PageText from '../../Components/Text/PageText';
import Spacer from '../../Components/Spacer';

const customStyles = {
    valueContainer: (provided, state) => ({
        ...provided,
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "initial"
    }),
    input: (provided, state) => ({
        ...provided,
        minWidth: '20%'
    })
}

const ValueContainer = ({ selectProps, children, ...props }) => {
    let [values, input] = children;

    if (Array.isArray(values)) {
        values = selectProps.value.map((x) => x.DisplayName).join(', ');
    }

    return (
      <components.ValueContainer {...props}>
        <div
            style={{
                maxWidth: "80%",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                marginBottom: '0px'
            }}
        >
            {values}
        </div>
        {input}
      </components.ValueContainer>
    );
};

class MonthlyProducts extends React.Component {
    state = {
        DHCId: 0,
        Month: new Date().getMonth() + 1,
        Year: new Date().getFullYear(),

        Loading_All: true,
        Loading_DHC: false,

        Products_ByTrainer: [],
        Sums_ByTrainer: [],

        Products_InAbel: [],
        Sums: [],

        OrgProducts: [],

        LoadedOrgMembers: false,
        OrgMembers: []
    };
    
    componentDidMount() {
        if (!this.props.UserDetails.DataCenterAccess) history.push('/clients');
        else this.onLoadMonthlyProducts()
    }

    onChangeMonthYear = event => {
        var [ Year, Month ] = event.target.value.split('-');

        this.setState({ Year, Month }, () => {
            this.onLoadMonthlyProducts();
            if (!!+this.state.DHCId) this.onLoadMonthlyProducts_ByDHC();
        })
    }

    onSelectDHC = ({ UserId }) => {
        this.setState({ DHCId: UserId }, () => this.onLoadMonthlyProducts_ByDHC());
    }

    onLoadMonthlyProducts = () => {
        var { Loading_All, Month, Year } = this.state;

        var GetOrgMembers = 0;
        if (!!+Loading_All) GetOrgMembers = 1; // Only load on first time

        this.setState({ Loading_All: true }, () => {
            this.props.GetMonthlyProducts({ GetOrgMembers, Month, Year }).then(({ OrgMembers, Products, Sums }) => {
                var StateOrgMembers = [ ...this.state.OrgMembers ];
                if (!!+GetOrgMembers) StateOrgMembers = [ ...OrgMembers ];
    
                this.setState({ Loading_All: false, OrgMembers: StateOrgMembers, OrgProducts: Products, Sums });
            });
        });
    }

    onLoadMonthlyProducts_ByDHC = () => {
        var { DHCId, Month, Year } = this.state;

        this.setState({ Loading_DHC: true }, () => {
            this.props.GetMonthlyProducts({ DHCId, Month, Year }).then(({ Products, Sums }) => {
                this.setState({ Loading_DHC: false, Products_ByTrainer: Products, Sums_ByTrainer: Sums });
            });
        });
    }

    renderProductTable = ({ data }) => {
        return (
            <table>
                <thead>
                    <tr>
                        <th><PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Product Id" TextAlign="left" /></th>
                        <th><PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Product Name" TextAlign="left" /></th>
                        <th><PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Price" TextAlign="left" /></th>
                        <th><PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Quantity" TextAlign="left" /></th>
                        <th><PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="In Abel Org" TextAlign="left" /></th>
                        <th><PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text="Is Abel Product" TextAlign="left" /></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map(({ InAbel, IsAbelProduct, ProductId, ProductName, Price, Quantity }, dataIndex) => {
                            return (
                                <tr key={dataIndex}>
                                    <td><PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${ProductId}`} TextAlign="left" /></td>
                                    <td><PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={ProductName} TextAlign="left" /></td>
                                    <td><PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${new Intl.NumberFormat('no-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Price)}`} TextAlign="left" /></td>
                                    <td><PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${Quantity}`} TextAlign="left" /></td>
                                    <td><PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${InAbel}`} TextAlign="left" /></td>
                                    <td><PageText FontFamily="medium" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`${IsAbelProduct}`} TextAlign="left" /></td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        );
    }

    renderBody = () => {
        var { OrgMembers, Products_ByTrainer, OrgProducts, Sums, Sums_ByTrainer, Month, Year } = this.state;

        return (
            <>
                <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text="Choose month" TextAlign="left" />

                <input type="month" onChange={this.onChangeMonthYear} id="start" name="start" min="2020-01" value={`${Year}-${Month}`} />

                <Spacer Size="medium" />

                <styles.BodyContainer className="BodyContainer">
                    {
                        Sums.length > 0 &&
                        <>
                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`In Abel: ${Sums[0].Quantity} products for total of ${new Intl.NumberFormat('no-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Sums[0].Price)} nok`} TextAlign="left" />

                            <Spacer Size="extra-extra-small" />

                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`NOT In Abel: ${Sums[1].Quantity} products for total of ${new Intl.NumberFormat('no-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Sums[1].Price)} nok`} TextAlign="left" />

                            <Spacer Size="extra-extra-small" />

                            <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`WITH Abel Products: ${Sums[2].Quantity} products for total of ${new Intl.NumberFormat('no-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Sums[2].Price)} nok`} TextAlign="left" />
                        </>
                    }

                    <Spacer Size="small" />

                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text="In Abel" TextAlign="left" />
                    {this.renderProductTable({ data: OrgProducts })}

                    <Spacer Size="medium" />

                    <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text="Filter by DHC" TextAlign="left" />
                    
                    <Spacer Size="extra-small" />
                    
                    <Select
                        closeMenuOnSelect={true}
                        defaultValue={[]}
                        getOptionLabel={option => option.DisplayName}
                        getOptionValue={option => option.UserId}
                        components={{ ValueContainer }}
                        hideSelectedOptions={false}
                        isSearchable
                        onChange={this.onSelectDHC}
                        options={OrgMembers}
                        placeholder="Select"
                        styles={customStyles}
                    />

                    {
                        !!+this.state.DHCId &&
                        <>
                            <Spacer Size="small" />

                            <PageText FontFamily="semibold" FontSize="medium-2" JustifyContent="flex-start" NoMargin Text="By Trainer" TextAlign="left" />

                            <Spacer Size="small" />

                            {
                                Sums_ByTrainer.length > 0 &&
                                <>
                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`In Abel: ${Sums_ByTrainer[0].Quantity} products for total of ${new Intl.NumberFormat('no-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Sums_ByTrainer[0].Price)} nok`} TextAlign="left" />

                                    <Spacer Size="extra-extra-small" />
        
                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`NOT In Abel: ${Sums_ByTrainer[1].Quantity} products for total of ${new Intl.NumberFormat('no-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Sums_ByTrainer[1].Price)} nok`} TextAlign="left" />

                                    <Spacer Size="extra-extra-small" />

                                    <PageText FontFamily="semibold" FontSize="medium-1" JustifyContent="flex-start" NoMargin Text={`WITH Abel Products: ${Sums_ByTrainer[2].Quantity} products for total of ${new Intl.NumberFormat('no-NO', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Sums_ByTrainer[2].Price)} nok`} TextAlign="left" />
                                </>
                            }

                            <Spacer Size="small" />

                            {this.renderProductTable({ data: Products_ByTrainer })}
                        </>
                    }
                </styles.BodyContainer>
            </>
        );
    }

    render() {
        var { TryingGetDataCenterDashboard } = this.props;

        return (
            <Page>
                {(this.state.Loading_All || this.state.Loading_DHC || TryingGetDataCenterDashboard) && <Loading />}

                {this.renderBody()}
            </Page>
        );
    }
}

const mapStateToProps = state => {
    return {
        Device: state.Window.Device,

        UserDetails: state.Auth.UserDetails,

        TryingGetDataCenterDashboard: state.DataCenter.TryingGetDataCenterDashboard,
        TryingGetDataCenterDashboardError: state.DataCenter.TryingGetDataCenterDashboardError
    };
};

export default withTranslation()(connect(mapStateToProps, { GetMonthlyProducts })(MonthlyProducts));