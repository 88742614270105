export const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL';
export const UPDATE_ERROR_MODAL_WARNING = 'UPDATE_ERROR_MODAL_WARNING';

export const CLEAR_GET_STARTED = 'CLEAR_GET_STARTED';
export const HANDLE_VISIBILITY_CHANGE = 'HANDLE_VISIBILITY_CHANGE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RESIZE_WINDOW = 'RESIZE_WINDOW';

export const INIT_WS = 'INIT_WS';

export const CLEAR_SAVE_DATA = 'CLEAR_SAVE_DATA';
export const SAVE_DATA_IN_PROPS = 'SAVE_DATA_IN_PROPS';

export const CLEAR_NEWLY_READ_NOTIFICATION_ID = 'CLEAR_NEWLY_READ_NOTIFICATION_ID';

export const TRYING_ADD_ADDITIONAL_ACTIVITIES = 'TRYING_ADD_ADDITIONAL_ACTIVITIES';
export const TRYING_ADD_ADDITIONAL_ACTIVITIES_SUCCESS = 'TRYING_ADD_ADDITIONAL_ACTIVITIES_SUCCESS';
export const TRYING_ADD_ADDITIONAL_ACTIVITIES_ERROR = 'TRYING_ADD_ADDITIONAL_ACTIVITIES_ERROR';

export const TRYING_ADD_CLIENT_JOURNAL_ENTRY = 'TRYING_ADD_CLIENT_JOURNAL_ENTRY';
export const TRYING_ADD_CLIENT_JOURNAL_ENTRY_SUCCESS = 'TRYING_ADD_CLIENT_JOURNAL_ENTRY_SUCCESS';
export const TRYING_ADD_CLIENT_JOURNAL_ENTRY_ERROR = 'TRYING_ADD_CLIENT_JOURNAL_ENTRY_ERROR';

export const TRYING_ADD_COMPANY_EMPLOYEES = 'TRYING_ADD_COMPANY_EMPLOYEES';
export const TRYING_ADD_COMPANY_EMPLOYEES_SUCCESS = 'TRYING_ADD_COMPANY_EMPLOYEES_SUCCESS';
export const TRYING_ADD_COMPANY_EMPLOYEES_ERROR = 'TRYING_ADD_COMPANY_EMPLOYEES_ERROR';

export const TRYING_ADD_EXISTING_DISH = 'TRYING_ADD_EXISTING_DISH';
export const TRYING_ADD_EXISTING_DISH_SUCCESS = 'TRYING_ADD_EXISTING_DISH_SUCCESS';
export const TRYING_ADD_EXISTING_DISH_ERROR = 'TRYING_ADD_EXISTING_DISH_ERROR';

export const TRYING_ADD_GET_STARTED_MEALS = 'TRYING_ADD_GET_STARTED_MEALS';
export const TRYING_ADD_GET_STARTED_MEALS_SUCCESS = 'TRYING_ADD_GET_STARTED_MEALS_SUCCESS';
export const TRYING_ADD_GET_STARTED_MEALS_ERROR = 'TRYING_ADD_GET_STARTED_MEALS_ERROR';

export const TRYING_ADD_GET_STARTED_WORKOUTS = 'TRYING_ADD_GET_STARTED_WORKOUTS';
export const TRYING_ADD_GET_STARTED_WORKOUTS_SUCCESS = 'TRYING_ADD_GET_STARTED_WORKOUTS_SUCCESS';
export const TRYING_ADD_GET_STARTED_WORKOUTS_ERROR = 'TRYING_ADD_GET_STARTED_WORKOUTS_ERROR';

export const TRYING_ADD_EXISTING_MEAL_TEMPLATE = 'TRYING_ADD_EXISTING_MEAL_TEMPLATE';
export const TRYING_ADD_EXISTING_MEAL_TEMPLATE_SUCCESS = 'TRYING_ADD_EXISTING_MEAL_TEMPLATE_SUCCESS';
export const TRYING_ADD_EXISTING_MEAL_TEMPLATE_ERROR = 'TRYING_ADD_EXISTING_MEAL_TEMPLATE_ERROR';

export const TRYING_ADD_EXISTING_WORKOUT_TEMPLATE = 'TRYING_ADD_EXISTING_WORKOUT_TEMPLATE';
export const TRYING_ADD_EXISTING_WORKOUT_TEMPLATE_SUCCESS = 'TRYING_ADD_EXISTING_WORKOUT_TEMPLATE_SUCCESS';
export const TRYING_ADD_EXISTING_WORKOUT_TEMPLATE_ERROR = 'TRYING_ADD_EXISTING_WORKOUT_TEMPLATE_ERROR';

export const TRYING_ADD_GROUP_WORKOUT_MEMBERS = 'TRYING_ADD_GROUP_WORKOUT_MEMBERS';
export const TRYING_ADD_GROUP_WORKOUT_MEMBERS_SUCCESS = 'TRYING_ADD_GROUP_WORKOUT_MEMBERS_SUCCESS';
export const TRYING_ADD_GROUP_WORKOUT_MEMBERS_ERROR = 'TRYING_ADD_GROUP_WORKOUT_MEMBERS_ERROR';

export const TRYING_ADD_LOG_FOOD = 'TRYING_ADD_LOG_FOOD';
export const TRYING_ADD_LOG_FOOD_SUCCESS = 'TRYING_ADD_LOG_FOOD_SUCCESS';
export const TRYING_ADD_LOG_FOOD_ERROR = 'TRYING_ADD_LOG_FOOD_ERROR';

export const TRYING_ADD_MEASUREMENTS = 'TRYING_ADD_MEASUREMENTS';
export const TRYING_ADD_MEASUREMENTS_SUCCESS = 'TRYING_ADD_MEASUREMENTS_SUCCESS';
export const TRYING_ADD_MEASUREMENTS_ERROR = 'TRYING_ADD_MEASUREMENTS_ERROR';

export const TRYING_ADD_NEW_WORKOUT = 'TRYING_ADD_NEW_WORKOUT';
export const TRYING_ADD_NEW_WORKOUT_SUCCESS = 'TRYING_ADD_NEW_WORKOUT_SUCCESS';
export const TRYING_ADD_NEW_WORKOUT_ERROR = 'TRYING_ADD_NEW_WORKOUT_ERROR';

export const TRYING_ADD_SINGLE_GENERATED_WORKOUT = 'TRYING_ADD_SINGLE_GENERATED_WORKOUT';
export const TRYING_ADD_SINGLE_GENERATED_WORKOUT_SUCCESS = 'TRYING_ADD_SINGLE_GENERATED_WORKOUT_SUCCESS';
export const TRYING_ADD_SINGLE_GENERATED_WORKOUT_ERROR = 'TRYING_ADD_SINGLE_GENERATED_WORKOUT_ERROR';

export const CLEAR_ADD_TRAINER_CLIENT_ERROR = 'CLEAR_ADD_TRAINER_CLIENT_ERROR';
export const TRYING_ADD_TRAINER_CLIENT = 'TRYING_ADD_TRAINER_CLIENT';
export const TRYING_ADD_TRAINER_CLIENT_SUCCESS = 'TRYING_ADD_TRAINER_CLIENT_SUCCESS';
export const TRYING_ADD_TRAINER_CLIENT_ERROR = 'TRYING_ADD_TRAINER_CLIENT_ERROR';

export const TRYING_ADD_TRAINING_PROGRAM = 'TRYING_ADD_TRAINING_PROGRAM';
export const TRYING_ADD_TRAINING_PROGRAM_SUCCESS = 'TRYING_ADD_TRAINING_PROGRAM_SUCCESS';
export const TRYING_ADD_TRAINING_PROGRAM_ERROR = 'TRYING_ADD_TRAINING_PROGRAM_ERROR';

export const TRYING_ADD_WORKOUT_EXERCISES = 'TRYING_ADD_WORKOUT_EXERCISES';
export const TRYING_ADD_WORKOUT_EXERCISES_SUCCESS = 'TRYING_ADD_WORKOUT_EXERCISES_SUCCESS';
export const TRYING_ADD_WORKOUT_EXERCISES_ERROR = 'TRYING_ADD_WORKOUT_EXERCISES_ERROR';

export const TRYING_ADD_WORKOUT_LAP = 'TRYING_ADD_WORKOUT_LAP';
export const TRYING_ADD_WORKOUT_LAP_SUCCESS = 'TRYING_ADD_WORKOUT_LAP_SUCCESS';
export const TRYING_ADD_WORKOUT_LAP_ERROR = 'TRYING_ADD_WORKOUT_LAP_ERROR';

export const TRYING_ADD_WORKOUT_SET = 'TRYING_ADD_WORKOUT_SET';
export const TRYING_ADD_WORKOUT_SET_SUCCESS = 'TRYING_ADD_WORKOUT_SET_SUCCESS';
export const TRYING_ADD_WORKOUT_SET_ERROR = 'TRYING_ADD_WORKOUT_SET_ERROR';

export const TRYING_AUTOCOMPLETE_WORKOUT = 'TRYING_AUTOCOMPLETE_WORKOUT';
export const TRYING_AUTOCOMPLETE_WORKOUT_SUCCESS = 'TRYING_AUTOCOMPLETE_WORKOUT_SUCCESS';
export const TRYING_AUTOCOMPLETE_WORKOUT_ERROR = 'TRYING_AUTOCOMPLETE_WORKOUT_ERROR';

export const TRYING_BUILD_WORKOUT_PLAN = 'TRYING_BUILD_WORKOUT_PLAN';
export const TRYING_BUILD_WORKOUT_PLAN_SUCCESS = 'TRYING_BUILD_WORKOUT_PLAN_SUCCESS';
export const TRYING_BUILD_WORKOUT_PLAN_ERROR = 'TRYING_BUILD_WORKOUT_PLAN_ERROR';

export const TRYING_BULK_ADD_CLIENTS = 'TRYING_BULK_ADD_CLIENTS';
export const TRYING_BULK_ADD_CLIENTS_SUCCESS = 'TRYING_BULK_ADD_CLIENTS_SUCCESS';
export const TRYING_BULK_ADD_CLIENTS_ERROR = 'TRYING_BULK_ADD_CLIENTS_ERROR';

export const TRYING_CALCULATE_BODY_FAT = 'TRYING_CALCULATE_BODY_FAT';
export const TRYING_CALCULATE_BODY_FAT_SUCCESS = 'TRYING_CALCULATE_BODY_FAT_SUCCESS';
export const TRYING_CALCULATE_BODY_FAT_ERROR = 'TRYING_CALCULATE_BODY_FAT_ERROR';

export const TRYING_CHANGE_DISHES_CATEGORY = 'TRYING_CHANGE_DISHES_CATEGORY';
export const TRYING_CHANGE_DISHES_CATEGORY_SUCCESS = 'TRYING_CHANGE_DISHES_CATEGORY_SUCCESS';
export const TRYING_CHANGE_DISHES_CATEGORY_ERROR = 'TRYING_CHANGE_DISHES_CATEGORY_ERROR';

export const TRYING_CHANGE_PASSWORD = 'TRYING_CHANGE_PASSWORD';
export const TRYING_CHANGE_PASSWORD_SUCCESS = 'TRYING_CHANGE_PASSWORD_SUCCESS';
export const TRYING_CHANGE_PASSWORD_ERROR = 'TRYING_CHANGE_PASSWORD_ERROR';

export const TRYING_CHANGE_SELECTED_DISHES_RATING = 'TRYING_CHANGE_SELECTED_DISHES_RATING';
export const TRYING_CHANGE_SELECTED_DISHES_RATING_SUCCESS = 'TRYING_CHANGE_SELECTED_DISHES_RATING_SUCCESS';
export const TRYING_CHANGE_SELECTED_DISHES_RATING_ERROR = 'TRYING_CHANGE_SELECTED_DISHES_RATING_ERROR';

export const TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET = 'TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET';
export const TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET_SUCCESS = 'TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET_SUCCESS';
export const TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET_ERROR = 'TRYING_CHANGE_WORKOUT_EXERCISE_MAX_SET_ERROR';

export const TRYING_CHANGE_WORKOUT_EXERCISE_NOTE = 'TRYING_CHANGE_WORKOUT_EXERCISE_NOTE';
export const TRYING_CHANGE_WORKOUT_EXERCISE_NOTE_SUCCESS = 'TRYING_CHANGE_WORKOUT_EXERCISE_NOTE_SUCCESS';
export const TRYING_CHANGE_WORKOUT_EXERCISE_NOTE_ERROR = 'TRYING_CHANGE_WORKOUT_EXERCISE_NOTE_ERROR';

export const TRYING_CHARGE_SUBSCRIPTION = 'TRYING_CHARGE_SUBSCRIPTION';
export const TRYING_CHARGE_SUBSCRIPTION_SUCCESS = 'TRYING_CHARGE_SUBSCRIPTION_SUCCESS';
export const TRYING_CHARGE_SUBSCRIPTION_ERROR = 'TRYING_CHARGE_SUBSCRIPTION_ERROR';

export const TRYING_CHOOSE_PRIMARY_ORGANIZATION = 'TRYING_CHOOSE_PRIMARY_ORGANIZATION';
export const TRYING_CHOOSE_PRIMARY_ORGANIZATION_SUCCESS = 'TRYING_CHOOSE_PRIMARY_ORGANIZATION_SUCCESS';
export const TRYING_CHOOSE_PRIMARY_ORGANIZATION_ERROR = 'TRYING_CHOOSE_PRIMARY_ORGANIZATION_ERROR';

export const TRYING_COPY_GROUP_WORKOUTS = 'TRYING_COPY_GROUP_WORKOUTS';
export const TRYING_COPY_GROUP_WORKOUTS_SUCCESS = 'TRYING_COPY_GROUP_WORKOUTS_SUCCESS';
export const TRYING_COPY_GROUP_WORKOUTS_ERROR = 'TRYING_COPY_GROUP_WORKOUTS_ERROR';

export const TRYING_COPY_MEALS = 'TRYING_COPY_MEALS';
export const TRYING_COPY_MEALS_SUCCESS = 'TRYING_COPY_MEALS_SUCCESS';
export const TRYING_COPY_MEALS_ERROR = 'TRYING_COPY_MEALS_ERROR';

export const TRYING_COPY_WORKOUTS = 'TRYING_COPY_WORKOUTS';
export const TRYING_COPY_WORKOUTS_SUCCESS = 'TRYING_COPY_WORKOUTS_SUCCESS';
export const TRYING_COPY_WORKOUTS_ERROR = 'TRYING_COPY_WORKOUTS_ERROR';

export const TRYING_CREATE_CONTRACT = 'TRYING_CREATE_CONTRACT';
export const TRYING_CREATE_CONTRACT_SUCCESS = 'TRYING_CREATE_CONTRACT_SUCCESS';
export const TRYING_CREATE_CONTRACT_ERROR = 'TRYING_CREATE_CONTRACT_ERROR';

export const TRYING_CREATE_DISH_CATEGORY = 'TRYING_CREATE_DISH_CATEGORY';
export const TRYING_CREATE_DISH_CATEGORY_SUCCESS = 'TRYING_CREATE_DISH_CATEGORY_SUCCESS';
export const TRYING_CREATE_DISH_CATEGORY_ERROR = 'TRYING_CREATE_DISH_CATEGORY_ERROR';

export const TRYING_CREATE_VIDEO_SESSION = 'TRYING_CREATE_VIDEO_SESSION';
export const TRYING_CREATE_VIDEO_SESSION_SUCCESS = 'TRYING_CREATE_VIDEO_SESSION_SUCCESS';
export const TRYING_CREATE_VIDEO_SESSION_ERROR = 'TRYING_CREATE_VIDEO_SESSION_ERROR';

export const TRYING_DELETE_BODYGOAL_MEASUREMENT = 'TRYING_DELETE_BODYGOAL_MEASUREMENT';
export const TRYING_DELETE_BODYGOAL_MEASUREMENT_SUCCESS = 'TRYING_DELETE_BODYGOAL_MEASUREMENT_SUCCESS';
export const TRYING_DELETE_BODYGOAL_MEASUREMENT_ERROR = 'TRYING_DELETE_BODYGOAL_MEASUREMENT_ERROR';

export const TRYING_DELETE_COMPANY_EMPLOYEES = 'TRYING_DELETE_COMPANY_EMPLOYEES';
export const TRYING_DELETE_COMPANY_EMPLOYEES_SUCCESS = 'TRYING_DELETE_COMPANY_EMPLOYEES_SUCCESS';
export const TRYING_DELETE_COMPANY_EMPLOYEES_ERROR = 'TRYING_DELETE_COMPANY_EMPLOYEES_ERROR';

export const TRYING_DELETE_CONTRACT = 'TRYING_DELETE_CONTRACT';
export const TRYING_DELETE_CONTRACT_SUCCESS = 'TRYING_DELETE_CONTRACT_SUCCESS';
export const TRYING_DELETE_CONTRACT_ERROR = 'TRYING_DELETE_CONTRACT_ERROR';

export const TRYING_DELETE_DISHES = 'TRYING_DELETE_DISHES';
export const TRYING_DELETE_DISHES_SUCCESS = 'TRYING_DELETE_DISHES_SUCCESS';
export const TRYING_DELETE_DISHES_ERROR = 'TRYING_DELETE_DISHES_ERROR';

export const TRYING_DELETE_DISH_DETAIL_FOOD = 'TRYING_DELETE_DISH_DETAIL_FOOD';
export const TRYING_DELETE_DISH_DETAIL_FOOD_SUCCESS = 'TRYING_DELETE_DISH_DETAIL_FOOD_SUCCESS';
export const TRYING_DELETE_DISH_DETAIL_FOOD_ERROR = 'TRYING_DELETE_DISH_DETAIL_FOOD_ERROR';

export const TRYING_DELETE_EXERCISES = 'TRYING_DELETE_EXERCISES';
export const TRYING_DELETE_EXERCISES_SUCCESS = 'TRYING_DELETE_EXERCISES_SUCCESS';
export const TRYING_DELETE_EXERCISES_ERROR = 'TRYING_DELETE_EXERCISES_ERROR';

export const TRYING_DELETE_EXPENSE = 'TRYING_DELETE_EXPENSE';
export const TRYING_DELETE_EXPENSE_SUCCESS = 'TRYING_DELETE_EXPENSE_SUCCESS';
export const TRYING_DELETE_EXPENSE_ERROR = 'TRYING_DELETE_EXPENSE_ERROR';

export const TRYING_DELETE_FOODS = 'TRYING_DELETE_FOODS';
export const TRYING_DELETE_FOODS_SUCCESS = 'TRYING_DELETE_FOODS_SUCCESS';
export const TRYING_DELETE_FOODS_ERROR = 'TRYING_DELETE_FOODS_ERROR';

export const TRYING_DELETE_FOODS_CONFIRMATION = 'TRYING_DELETE_FOODS_CONFIRMATION';
export const TRYING_DELETE_FOODS_CONFIRMATION_SUCCESS = 'TRYING_DELETE_FOODS_CONFIRMATION_SUCCESS';
export const TRYING_DELETE_FOODS_CONFIRMATION_ERROR = 'TRYING_DELETE_FOODS_CONFIRMATION_ERROR';

export const TRYING_DELETE_GROUP_WORKOUTS = 'TRYING_DELETE_GROUP_WORKOUTS';
export const TRYING_DELETE_GROUP_WORKOUTS_SUCCESS = 'TRYING_DELETE_GROUP_WORKOUTS_SUCCESS';
export const TRYING_DELETE_GROUP_WORKOUTS_ERROR = 'TRYING_DELETE_GROUP_WORKOUTS_ERROR';

export const TRYING_DELETE_MEAL_DISH = 'TRYING_DELETE_MEAL_DISH';
export const TRYING_DELETE_MEAL_DISH_SUCCESS = 'TRYING_DELETE_MEAL_DISH_SUCCESS';
export const TRYING_DELETE_MEAL_DISH_ERROR = 'TRYING_DELETE_MEAL_DISH_ERROR';

export const TRYING_DELETE_MEAL_TEMPLATES = 'TRYING_DELETE_MEAL_TEMPLATES';
export const TRYING_DELETE_MEAL_TEMPLATES_SUCCESS = 'TRYING_DELETE_MEAL_TEMPLATES_SUCCESS';
export const TRYING_DELETE_MEAL_TEMPLATES_ERROR = 'TRYING_DELETE_MEAL_TEMPLATES_ERROR';

export const TRYING_DELETE_MEALS = 'TRYING_DELETE_MEALS';
export const TRYING_DELETE_MEALS_SUCCESS = 'TRYING_DELETE_MEALS_SUCCESS';
export const TRYING_DELETE_MEALS_ERROR = 'TRYING_DELETE_MEALS_ERROR';

export const TRYING_DELETE_ORGANIZATION_BRANCH = 'TRYING_DELETE_ORGANIZATION_BRANCH';
export const TRYING_DELETE_ORGANIZATION_BRANCH_SUCCESS = 'TRYING_DELETE_ORGANIZATION_BRANCH_SUCCESS';
export const TRYING_DELETE_ORGANIZATION_BRANCH_ERROR = 'TRYING_DELETE_ORGANIZATION_BRANCH_ERROR';

export const TRYING_DELETE_ORGANIZATION_MEMBER = 'TRYING_DELETE_ORGANIZATION_MEMBER';
export const TRYING_DELETE_ORGANIZATION_MEMBER_SUCCESS = 'TRYING_DELETE_ORGANIZATION_MEMBER_SUCCESS';
export const TRYING_DELETE_ORGANIZATION_MEMBER_ERROR = 'TRYING_DELETE_ORGANIZATION_MEMBER_ERROR';

export const TRYING_DELETE_ORGANIZATION_REGION = 'TRYING_DELETE_ORGANIZATION_REGION';
export const TRYING_DELETE_ORGANIZATION_REGION_SUCCESS = 'TRYING_DELETE_ORGANIZATION_REGION_SUCCESS';
export const TRYING_DELETE_ORGANIZATION_REGION_ERROR = 'TRYING_DELETE_ORGANIZATION_REGION_ERROR';

export const TRYING_DELETE_PRODUCTS = 'TRYING_DELETE_PRODUCTS';
export const TRYING_DELETE_PRODUCTS_SUCCESS = 'TRYING_DELETE_PRODUCTS_SUCCESS';
export const TRYING_DELETE_PRODUCTS_ERROR = 'TRYING_DELETE_PRODUCTS_ERROR';

export const TRYING_DELETE_TRAINER_CLIENT = 'TRYING_DELETE_TRAINER_CLIENT';
export const TRYING_DELETE_TRAINER_CLIENT_SUCCESS = 'TRYING_DELETE_TRAINER_CLIENT_SUCCESS';
export const TRYING_DELETE_TRAINER_CLIENT_ERROR = 'TRYING_DELETE_TRAINER_CLIENT_ERROR';

export const TRYING_DELETE_TRAINER_GROUP = 'TRYING_DELETE_TRAINER_GROUP';
export const TRYING_DELETE_TRAINER_GROUP_SUCCESS = 'TRYING_DELETE_TRAINER_GROUP_SUCCESS';
export const TRYING_DELETE_TRAINER_GROUP_ERROR = 'TRYING_DELETE_TRAINER_GROUP_ERROR';

export const TRYING_DELETE_TRAINING_PROGRAMS = 'TRYING_DELETE_TRAINING_PROGRAMS';
export const TRYING_DELETE_TRAINING_PROGRAMS_SUCCESS = 'TRYING_DELETE_TRAINING_PROGRAMS_SUCCESS';
export const TRYING_DELETE_TRAINING_PROGRAMS_ERROR = 'TRYING_DELETE_TRAINING_PROGRAMS_ERROR';

export const TRYING_DELETE_VIDEO_SESSION = "TRYING_DELETE_VIDEO_SESSION";
export const TRYING_DELETE_VIDEO_SESSION_SUCCESS = "TRYING_DELETE_VIDEO_SESSION_SUCCESS";
export const TRYING_DELETE_VIDEO_SESSION_ERROR = "TRYING_DELETE_VIDEO_SESSION_ERROR";

export const TRYING_DELETE_WORKOUT = 'TRYING_DELETE_WORKOUT';
export const TRYING_DELETE_WORKOUT_SUCCESS = 'TRYING_DELETE_WORKOUT_SUCCESS';
export const TRYING_DELETE_WORKOUT_ERROR = 'TRYING_DELETE_WORKOUT_ERROR';

export const TRYING_DELETE_WORKOUT_EXERCISE = 'TRYING_DELETE_WORKOUT_EXERCISE';
export const TRYING_DELETE_WORKOUT_EXERCISE_SUCCESS = 'TRYING_DELETE_WORKOUT_EXERCISE_SUCCESS';
export const TRYING_DELETE_WORKOUT_EXERCISE_ERROR = 'TRYING_DELETE_WORKOUT_EXERCISE_ERROR';

export const TRYING_DELETE_WORKOUT_LAP = 'TRYING_DELETE_WORKOUT_LAP';
export const TRYING_DELETE_WORKOUT_LAP_SUCCESS = 'TRYING_DELETE_WORKOUT_LAP_SUCCESS';
export const TRYING_DELETE_WORKOUT_LAP_ERROR = 'TRYING_DELETE_WORKOUT_LAP_ERROR';

export const TRYING_DELETE_WORKOUT_SET = 'TRYING_DELETE_WORKOUT_SET';
export const TRYING_DELETE_WORKOUT_SET_SUCCESS = 'TRYING_DELETE_WORKOUT_SET_SUCCESS';
export const TRYING_DELETE_WORKOUT_SET_ERROR = 'TRYING_DELETE_WORKOUT_SET_ERROR';

export const TRYING_DELETE_WORKOUT_TEMPLATES = 'TRYING_DELETE_WORKOUT_TEMPLATES';
export const TRYING_DELETE_WORKOUT_TEMPLATES_SUCCESS = 'TRYING_DELETE_WORKOUT_TEMPLATES_SUCCESS';
export const TRYING_DELETE_WORKOUT_TEMPLATES_ERROR = 'TRYING_DELETE_WORKOUT_TEMPLATES_ERROR';

export const TRYING_DELETE_WORKOUTS = 'TRYING_DELETE_WORKOUTS';
export const TRYING_DELETE_WORKOUTS_SUCCESS = 'TRYING_DELETE_WORKOUTS_SUCCESS';
export const TRYING_DELETE_WORKOUTS_ERROR = 'TRYING_DELETE_WORKOUTS_ERROR';

export const TRYING_DELIVERY_SITE_ACTION = 'TRYING_DELIVERY_SITE_ACTION';
export const TRYING_DELIVERY_SITE_ACTION_SUCCESS = 'TRYING_DELIVERY_SITE_ACTION_SUCCESS';
export const TRYING_DELIVERY_SITE_ACTION_ERROR = 'TRYING_DELIVERY_SITE_ACTION_ERROR';

export const TRYING_DOWNLOAD_CONTRACT_PDF = 'TRYING_DOWNLOAD_CONTRACT_PDF';
export const TRYING_DOWNLOAD_CONTRACT_PDF_SUCCESS = 'TRYING_DOWNLOAD_CONTRACT_PDF_SUCCESS';
export const TRYING_DOWNLOAD_CONTRACT_PDF_ERROR = 'TRYING_DOWNLOAD_CONTRACT_PDF_ERROR';

export const TRYING_DOWNLOAD_EMPLOYEES = 'TRYING_DOWNLOAD_EMPLOYEES';
export const TRYING_DOWNLOAD_EMPLOYEES_SUCCESS = 'TRYING_DOWNLOAD_EMPLOYEES_SUCCESS';

export const TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT = 'TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT';
export const TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT_SUCCESS = 'TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT_SUCCESS';
export const TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT_ERROR = 'TRYING_DOWNLOAD_ORGANIZATION_INCOME_REPORT_ERROR';

export const TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY = 'TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY';
export const TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_SUCCESS = 'TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_SUCCESS';
export const TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_ERROR = 'TRYING_DOWNLOAD_TRAINER_PAYOUT_SUMMARY_ERROR';

export const TRYING_EDIT_GROUP_WORKOUT_DETAIL = 'TRYING_EDIT_GROUP_WORKOUT_DETAIL';
export const TRYING_EDIT_GROUP_WORKOUT_DETAIL_SUCCESS = 'TRYING_EDIT_GROUP_WORKOUT_DETAIL_SUCCESS';
export const TRYING_EDIT_GROUP_WORKOUT_DETAIL_ERROR = 'TRYING_EDIT_GROUP_WORKOUT_DETAIL_ERROR';

export const TRYING_EDIT_USER_PROFILE = 'TRYING_EDIT_USER_PROFILE';
export const TRYING_EDIT_USER_PROFILE_SUCCESS = 'TRYING_EDIT_USER_PROFILE_SUCCESS';
export const TRYING_EDIT_USER_PROFILE_ERROR = 'TRYING_EDIT_USER_PROFILE_ERROR';

export const CLEAR_SINGLE_VIDEO_SESSION = 'CLEAR_SINGLE_VIDEO_SESSION';
export const TRYING_EDIT_VIDEO_SESSION = 'TRYING_EDIT_VIDEO_SESSION';
export const TRYING_EDIT_VIDEO_SESSION_SUCCESS = 'TRYING_EDIT_VIDEO_SESSION_SUCCESS';
export const TRYING_EDIT_VIDEO_SESSION_ERROR = 'TRYING_EDIT_VIDEO_SESSION_ERROR';

export const TRYING_FORGOT_PASSWORD_SEND_CODE = 'TRYING_FORGOT_PASSWORD_SEND_CODE';
export const TRYING_FORGOT_PASSWORD_SEND_CODE_SUCCESS = 'TRYING_FORGOT_PASSWORD_SEND_CODE_SUCCESS';
export const TRYING_FORGOT_PASSWORD_SEND_CODE_ERROR = 'TRYING_FORGOT_PASSWORD_SEND_CODE_ERROR';

export const TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD = 'TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD';
export const TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD_SUCCESS = 'TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD_SUCCESS';
export const TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD_ERROR = 'TRYING_FORGOT_PASSWORD_UPDATE_PASSWORD_ERROR';

export const TRYING_FREEZE_WORKOUT_FOR_PRINT = 'TRYING_FREEZE_WORKOUT_FOR_PRINT';
export const TRYING_FREEZE_WORKOUT_FOR_PRINT_SUCCESS = 'TRYING_FREEZE_WORKOUT_FOR_PRINT_SUCCESS';
export const TRYING_FREEZE_WORKOUT_FOR_PRINT_ERROR = 'TRYING_FREEZE_WORKOUT_FOR_PRINT_ERROR';

export const TRYING_GENERATE_WORKOUT_TEMPLATE_PDF = 'TRYING_GENERATE_WORKOUT_TEMPLATE_PDF';
export const TRYING_GENERATE_WORKOUT_TEMPLATE_PDF_SUCCESS = 'TRYING_GENERATE_WORKOUT_TEMPLATE_PDF_SUCCESS';
export const TRYING_GENERATE_WORKOUT_TEMPLATE_PDF_ERROR = 'TRYING_GENERATE_WORKOUT_TEMPLATE_PDF_ERROR';

export const CLEAR_ACTIVITY_LEVELS = 'CLEAR_ACTIVITY_LEVELS';
export const TRYING_GET_ACTIVITY_LEVELS = 'TRYING_GET_ACTIVITY_LEVELS';
export const TRYING_GET_ACTIVITY_LEVELS_SUCCESS = 'TRYING_GET_ACTIVITY_LEVELS_SUCCESS';
export const TRYING_GET_ACTIVITY_LEVELS_ERROR = 'TRYING_GET_ACTIVITY_LEVELS_ERROR';

export const CLEAR_ADDITIONAL_ACTIVITY_DETAILS = 'CLEAR_ADDITIONAL_ACTIVITY_DETAILS';
export const TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS = 'TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS';
export const TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS = 'TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS';
export const TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_ERROR = 'TRYING_GET_ADDITIONAL_ACTIVITY_DETAILS_ERROR';

export const CLEAR_ADD_EDIT_GROUP_SETTINGS = 'CLEAR_ADD_EDIT_GROUP_SETTINGS';
export const TRYING_GET_ADD_EDIT_GROUP_SETTINGS = 'TRYING_GET_ADD_EDIT_GROUP_SETTINGS';
export const TRYING_GET_ADD_EDIT_GROUP_SETTINGS_SUCCESS = 'TRYING_GET_ADD_EDIT_GROUP_SETTINGS_SUCCESS';
export const TRYING_GET_ADD_EDIT_GROUP_SETTINGS_ERROR = 'TRYING_GET_ADD_EDIT_GROUP_SETTINGS_ERROR';

export const TRYING_GET_ADD_MEASUREMENT_DETAILS = 'TRYING_GET_ADD_MEASUREMENT_DETAILS';
export const TRYING_GET_ADD_MEASUREMENT_DETAILS_SUCCESS = 'TRYING_GET_ADD_MEASUREMENT_DETAILS_SUCCESS';
export const TRYING_GET_ADD_MEASUREMENT_DETAILS_ERROR = 'TRYING_GET_ADD_MEASUREMENT_DETAILS_ERROR';

export const TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT = 'TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT';
export const TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT_SUCCESS = 'TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT_SUCCESS';
export const TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT_ERROR = 'TRYING_GET_ALL_TRAINER_GROUPS_FOR_NEW_CLIENT_ERROR';

export const TRYING_GET_BODYGOAL_TRACKING_HISTORY = 'TRYING_GET_BODYGOAL_TRACKING_HISTORY';
export const TRYING_GET_BODYGOAL_TRACKING_HISTORY_SUCCESS = 'TRYING_GET_BODYGOAL_TRACKING_HISTORY_SUCCESS';
export const TRYING_GET_BODYGOAL_TRACKING_HISTORY_ERROR = 'TRYING_GET_BODYGOAL_TRACKING_HISTORY_ERROR';

export const CLEAR_BUILD_WORKOUT_PLAN_DETAILS = 'CLEAR_BUILD_WORKOUT_PLAN_DETAILS';
export const TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS = 'TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS';
export const TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS_SUCCESS = 'TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS_SUCCESS';
export const TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS_ERROR = 'TRYING_GET_BUILD_WORKOUT_PLAN_DETAILS_ERROR';

export const TRYING_GET_CHOOSE_CLIENTS = 'TRYING_GET_CHOOSE_CLIENTS';
export const TRYING_GET_CHOOSE_CLIENTS_SUCCESS = 'TRYING_GET_CHOOSE_CLIENTS_SUCCESS';
export const TRYING_GET_CHOOSE_CLIENTS_ERROR = 'TRYING_GET_CHOOSE_CLIENTS_ERROR';

export const CLEAR_CLIENT_DASHBOARD = 'CLEAR_CLIENT_DASHBOARD';
export const TRYING_GET_CLIENT_DASHBOARD = 'TRYING_GET_CLIENT_DASHBOARD';
export const TRYING_GET_CLIENT_DASHBOARD_SUCCESS = 'TRYING_GET_CLIENT_DASHBOARD_SUCCESS';
export const TRYING_GET_CLIENT_DASHBOARD_ERROR = 'TRYING_GET_CLIENT_DASHBOARD_ERROR';

export const TRYING_GET_CLIENT_DASHBOARD_EVENT = 'TRYING_GET_CLIENT_DASHBOARD_EVENT';
export const TRYING_GET_CLIENT_DASHBOARD_EVENT_SUCCESS = 'TRYING_GET_CLIENT_DASHBOARD_EVENT_SUCCESS';
export const TRYING_GET_CLIENT_DASHBOARD_EVENT_ERROR = 'TRYING_GET_CLIENT_DASHBOARD_EVENT_ERROR';

export const CLEAR_CLIENT_DETAILS = 'CLEAR_CLIENT_DETAILS';
export const TRYING_GET_CLIENT_DETAILS = 'TRYING_GET_CLIENT_DETAILS';
export const TRYING_GET_CLIENT_DETAILS_SUCCESS = 'TRYING_GET_CLIENT_DETAILS_SUCCESS';
export const TRYING_GET_CLIENT_DETAILS_ERROR = 'TRYING_GET_CLIENT_DETAILS_ERROR';

export const TRYING_GET_CLIENT_JOURNAL_ENTRIES = 'TRYING_GET_CLIENT_JOURNAL_ENTRIES';
export const TRYING_GET_CLIENT_JOURNAL_ENTRIES_SUCCESS = 'TRYING_GET_CLIENT_JOURNAL_ENTRIES_SUCCESS';
export const TRYING_GET_CLIENT_JOURNAL_ENTRIES_ERROR = 'TRYING_GET_CLIENT_JOURNAL_ENTRIES_ERROR';

export const TRYING_GET_CLIENT_MEALS = 'TRYING_GET_CLIENT_MEALS';
export const TRYING_GET_CLIENT_MEALS_SUCCESS = 'TRYING_GET_CLIENT_MEALS_SUCCESS';
export const TRYING_GET_CLIENT_MEALS_ERROR = 'TRYING_GET_CLIENT_MEALS_ERROR';

export const TRYING_GET_CLIENT_MEAL_SETTINGS = 'TRYING_GET_CLIENT_MEAL_SETTINGS';
export const TRYING_GET_CLIENT_MEAL_SETTINGS_SUCCESS = 'TRYING_GET_CLIENT_MEAL_SETTINGS_SUCCESS';
export const TRYING_GET_CLIENT_MEAL_SETTINGS_ERROR = 'TRYING_GET_CLIENT_MEAL_SETTINGS_ERROR';

export const TRYING_GET_CLIENTS = 'TRYING_GET_CLIENTS';
export const TRYING_GET_CLIENTS_SUCCESS = 'TRYING_GET_CLIENTS_SUCCESS';
export const TRYING_GET_CLIENTS_ERROR = 'TRYING_GET_CLIENTS_ERROR';

export const TRYING_GET_CLIENT_WORKOUTS = 'TRYING_GET_CLIENT_WORKOUTS';
export const TRYING_GET_CLIENT_WORKOUTS_SUCCESS = 'TRYING_GET_CLIENT_WORKOUTS_SUCCESS';
export const TRYING_GET_CLIENT_WORKOUTS_ERROR = 'TRYING_GET_CLIENT_WORKOUTS_ERROR';

export const TRYING_GET_COMPANIES = 'TRYING_GET_COMPANIES';
export const TRYING_GET_COMPANIES_SUCCESS = 'TRYING_GET_COMPANIES_SUCCESS';
export const TRYING_GET_COMPANIES_ERROR = 'TRYING_GET_COMPANIES_ERROR';

export const TRYING_GET_COMPANY = 'TRYING_GET_COMPANY';
export const TRYING_GET_COMPANY_SUCCESS = 'TRYING_GET_COMPANY_SUCCESS';
export const TRYING_GET_COMPANY_ERROR = 'TRYING_GET_COMPANY_ERROR';

export const CLEAR_CREATE_CONTRACT_DETAILS = 'CLEAR_CREATE_CONTRACT_DETAILS';
export const TRYING_GET_CREATE_CONTRACT_DETAILS = 'TRYING_GET_CREATE_CONTRACT_DETAILS';
export const TRYING_GET_CREATE_CONTRACT_DETAILS_SUCCESS = 'TRYING_GET_CREATE_CONTRACT_DETAILS_SUCCESS';
export const TRYING_GET_CREATE_CONTRACT_DETAILS_ERROR = 'TRYING_GET_CREATE_CONTRACT_DETAILS_ERROR';

export const CLEAR_PRIVATE_DISH_SETTINGS = 'CLEAR_PRIVATE_DISH_SETTINGS';
export const TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS = 'TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS';
export const TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS_SUCCESS = 'TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS_SUCCESS';
export const TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS_ERROR = 'TRYING_GET_CREATE_PRIVATE_DISH_SETTINGS_ERROR';

export const CLEAR_PRIVATE_EXERCISE_SETTINGS = 'CLEAR_PRIVATE_EXERCISE_SETTINGS';
export const TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS = 'TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS';
export const TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS_SUCCESS = 'TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS_SUCCESS';
export const TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS_ERROR = 'TRYING_GET_CREATE_PRIVATE_EXERCISE_SETTINGS_ERROR';

export const TRYING_GET_DATACENTER_DASHBOARD = 'TRYING_GET_DATACENTER_DASHBOARD';
export const TRYING_GET_DATACENTER_DASHBOARD_SUCCESS = 'TRYING_GET_DATACENTER_DASHBOARD_SUCCESS';
export const TRYING_GET_DATACENTER_DASHBOARD_ERROR = 'TRYING_GET_DATACENTER_DASHBOARD_ERROR';

export const TRYING_GET_DATACENTER_DASHBOARD_FILTERS = 'TRYING_GET_DATACENTER_DASHBOARD_FILTERS';
export const TRYING_GET_DATACENTER_DASHBOARD_FILTERS_SUCCESS = 'TRYING_GET_DATACENTER_DASHBOARD_FILTERS_SUCCESS';
export const TRYING_GET_DATACENTER_DASHBOARD_FILTERS_ERROR = 'TRYING_GET_DATACENTER_DASHBOARD_FILTERS_ERROR';

export const CLEAR_DISH_DETAIL = 'CLEAR_DISH_DETAIL';
export const TRYING_GET_DISH_DETAIL = 'TRYING_GET_DISH_DETAIL';
export const TRYING_GET_DISH_DETAIL_SUCCESS = 'TRYING_GET_DISH_DETAIL_SUCCESS';
export const TRYING_GET_DISH_DETAIL_ERROR = 'TRYING_GET_DISH_DETAIL_ERROR';

export const TRYING_GET_DISH_FILTER_SETTINGS = 'TRYING_GET_DISH_FILTER_SETTINGS';
export const TRYING_GET_DISH_FILTER_SETTINGS_SUCCESS = 'TRYING_GET_DISH_FILTER_SETTINGS_SUCCESS';
export const TRYING_GET_DISH_FILTER_SETTINGS_ERROR = 'TRYING_GET_DISH_FILTER_SETTINGS_ERROR';

export const CLEAR_ENDURANCE_DETAIL = 'CLEAR_ENDURANCE_DETAIL';
export const TRYING_GET_ENDURANCE_DETAIL = 'TRYING_GET_ENDURANCE_DETAIL';
export const TRYING_GET_ENDURANCE_DETAIL_SUCCESS = 'TRYING_GET_ENDURANCE_DETAIL_SUCCESS';
export const TRYING_GET_ENDURANCE_DETAIL_ERROR = 'TRYING_GET_ENDURANCE_DETAIL_ERROR';

export const TRYING_GET_ENDURANCE_TRACKING_HISTORY = 'TRYING_GET_ENDURANCE_TRACKING_HISTORY';
export const TRYING_GET_ENDURANCE_TRACKING_HISTORY_SUCCESS = 'TRYING_GET_ENDURANCE_TRACKING_HISTORY_SUCCESS';
export const TRYING_GET_ENDURANCE_TRACKING_HISTORY_ERROR = 'TRYING_GET_ENDURANCE_TRACKING_HISTORY_ERROR';

export const CLEAR_EXERCISE_DETAIL = 'CLEAR_EXERCISE_DETAIL';
export const TRYING_GET_EXERCISE_DETAIL = 'TRYING_GET_EXERCISE_DETAIL';
export const TRYING_GET_EXERCISE_DETAIL_SUCCESS = 'TRYING_GET_EXERCISE_DETAIL_SUCCESS';
export const TRYING_GET_EXERCISE_DETAIL_ERROR = 'TRYING_GET_EXERCISE_DETAIL_ERROR';

export const TRYING_GET_EXERCISE_FILTER_SETTINGS = 'TRYING_GET_EXERCISE_FILTER_SETTINGS';
export const TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS = 'TRYING_GET_EXERCISE_FILTER_SETTINGS_SUCCESS';
export const TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR = 'TRYING_GET_EXERCISE_FILTER_SETTINGS_ERROR';

export const TRYING_GET_EXERCISE_TRACKING_HISTORY = 'TRYING_GET_EXERCISE_TRACKING_HISTORY';
export const TRYING_GET_EXERCISE_TRACKING_HISTORY_SUCCESS = 'TRYING_GET_EXERCISE_TRACKING_HISTORY_SUCCESS';
export const TRYING_GET_EXERCISE_TRACKING_HISTORY_ERROR = 'TRYING_GET_EXERCISE_TRACKING_HISTORY_ERROR';

export const TRYING_GET_EXPERIENCE_LEVELS = 'TRYING_GET_EXPERIENCE_LEVELS';
export const TRYING_GET_EXPERIENCE_LEVELS_SUCCESS = 'TRYING_GET_EXPERIENCE_LEVELS_SUCCESS';
export const TRYING_GET_EXPERIENCE_LEVELS_ERROR = 'TRYING_GET_EXPERIENCE_LEVELS_ERROR';

export const TRYING_GET_GARMIN_DATA = 'TRYING_GET_GARMIN_DATA';
export const TRYING_GET_GARMIN_DATA_SUCCESS = 'TRYING_GET_GARMIN_DATA_SUCCESS';
export const TRYING_GET_GARMIN_DATA_ERROR = 'TRYING_GET_GARMIN_DATA_ERROR';

export const TRYING_GET_GOALS = 'TRYING_GET_GOALS';
export const TRYING_GET_GOALS_SUCCESS = 'TRYING_GET_GOALS_SUCCESS';
export const TRYING_GET_GOALS_ERROR = 'TRYING_GET_GOALS_ERROR';

export const CLEAR_GROUP_DETAILS = 'CLEAR_GROUP_DETAILS';
export const TRYING_GET_GROUP_DETAILS = 'TRYING_GET_GROUP_DETAILS';
export const TRYING_GET_GROUP_DETAILS_SUCCESS = 'TRYING_GET_GROUP_DETAILS_SUCCESS';
export const TRYING_GET_GROUP_DETAILS_ERROR = 'TRYING_GET_GROUP_DETAILS_ERROR';

export const CLEAR_GROUP_WORKOUT_DETAIL = 'CLEAR_GROUP_WORKOUT_DETAIL';
export const TRYING_GET_GROUP_WORKOUT_DETAIL = 'TRYING_GET_GROUP_WORKOUT_DETAIL';
export const TRYING_GET_GROUP_WORKOUT_DETAIL_SUCCESS = 'TRYING_GET_GROUP_WORKOUT_DETAIL_SUCCESS';
export const TRYING_GET_GROUP_WORKOUT_DETAIL_ERROR = 'TRYING_GET_GROUP_WORKOUT_DETAIL_ERROR';

export const TRYING_GET_GROUP_WORKOUTS = 'TRYING_GET_GROUP_WORKOUTS';
export const TRYING_GET_GROUP_WORKOUTS_SUCCESS = 'TRYING_GET_GROUP_WORKOUTS_SUCCESS';
export const TRYING_GET_GROUP_WORKOUTS_ERROR = 'TRYING_GET_GROUP_WORKOUTS_ERROR';

export const CLEAR_GYMS = 'CLEAR_GYMS';
export const TRYING_GET_GYMS = 'TRYING_GET_GYMS';
export const TRYING_GET_GYMS_SUCCESS = 'TRYING_GET_GYMS_SUCCESS';
export const TRYING_GET_GYMS_ERROR = 'TRYING_GET_GYMS_ERROR';

export const TRYING_GET_INJURIES = 'TRYING_GET_INJURIES';
export const TRYING_GET_INJURIES_SUCCESS = 'TRYING_GET_INJURIES_SUCCESS';
export const TRYING_GET_INJURIES_ERROR = 'TRYING_GET_INJURIES_ERROR';

export const CLEAR_MEAL_TEMPLATE_DETAIL = 'CLEAR_MEAL_TEMPLATE_DETAIL';
export const TRYING_GET_MEAL_TEMPLATE_DETAIL = 'TRYING_GET_MEAL_TEMPLATE_DETAIL';
export const TRYING_GET_MEAL_TEMPLATE_DETAIL_SUCCESS = 'TRYING_GET_MEAL_TEMPLATE_DETAIL_SUCCESS';
export const TRYING_GET_MEAL_TEMPLATE_DETAIL_ERROR = 'TRYING_GET_MEAL_TEMPLATE_DETAIL_ERROR';

export const TRYING_GET_MEAL_DISH_PREVIEW = 'TRYING_GET_MEAL_DISH_PREVIEW';
export const TRYING_GET_MEAL_DISH_PREVIEW_SUCCESS = 'TRYING_GET_MEAL_DISH_PREVIEW_SUCCESS';
export const TRYING_GET_MEAL_DISH_PREVIEW_ERROR = 'TRYING_GET_MEAL_DISH_PREVIEW_ERROR';

export const CLEAR_NUTRITION = 'CLEAR_NUTRITION';
export const TRYING_GET_NUTRITION = 'TRYING_GET_NUTRITION';
export const TRYING_GET_NUTRITION_SUCCESS = 'TRYING_GET_NUTRITION_SUCCESS';
export const TRYING_GET_NUTRITION_ERROR = 'TRYING_GET_NUTRITION_ERROR';

export const TRYING_GET_ORGANIZATION_INCOME_REPORT = 'TRYING_GET_ORGANIZATION_INCOME_REPORT';
export const TRYING_GET_ORGANIZATION_INCOME_REPORT_SUCCESS = 'TRYING_GET_ORGANIZATION_INCOME_REPORT_SUCCESS';
export const TRYING_GET_ORGANIZATION_INCOME_REPORT_ERROR = 'TRYING_GET_ORGANIZATION_INCOME_REPORT_ERROR';

export const TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES = 'TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES';
export const TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES_SUCCESS = 'TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES_SUCCESS';
export const TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES_ERROR = 'TRYING_GET_ORGANIZATION_REGIONS_AND_BRANCHES_ERROR';

export const TRYING_GET_PRESIGNED_URL = 'TRYING_GET_PRESIGNED_URL';
export const TRYING_GET_PRESIGNED_URL_SUCCESS = 'TRYING_GET_PRESIGNED_URL_SUCCESS';
export const TRYING_GET_PRESIGNED_URL_ERROR = 'TRYING_GET_PRESIGNED_URL_ERROR';

export const CLEAR_PROGRESS_DASHBOARD = 'CLEAR_PROGRESS_DASHBOARD';
export const TRYING_GET_PROGRESS_DASHBOARD = 'TRYING_GET_PROGRESS_DASHBOARD';
export const TRYING_GET_PROGRESS_DASHBOARD_SUCCESS = 'TRYING_GET_PROGRESS_DASHBOARD_SUCCESS';
export const TRYING_GET_PROGRESS_DASHBOARD_ERROR = 'TRYING_GET_PROGRESS_DASHBOARD_ERROR';

export const CLEAR_SINGLE_GENERATED_WORKOUT_DETAILS = 'CLEAR_SINGLE_GENERATED_WORKOUT_DETAILS';
export const TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS = 'TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS';
export const TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS_SUCCESS = 'TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS_SUCCESS';
export const TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS_ERROR = 'TRYING_GET_SINGLE_GENERATED_WORKOUT_DETAILS_ERROR';

export const TRYING_GET_SINGLE_VIDEO_SESSION = 'TRYING_GET_SINGLE_VIDEO_SESSION';
export const TRYING_GET_SINGLE_VIDEO_SESSION_ERROR = 'TRYING_GET_SINGLE_VIDEO_SESSION_ERROR';
export const TRYING_GET_SINGLE_VIDEO_SESSION_SUCCESS = 'TRYING_GET_SINGLE_VIDEO_SESSION_SUCCESS';

export const CLEAR_TRAINER_CLIENTS = 'CLEAR_TRAINER_CLIENTS';
export const TRYING_GET_TRAINER_CLIENTS = 'TRYING_GET_TRAINER_CLIENTS';
export const TRYING_GET_TRAINER_CLIENTS_SUCCESS = 'TRYING_GET_TRAINER_CLIENTS_SUCCESS';
export const TRYING_GET_TRAINER_CLIENTS_ERROR = 'TRYING_GET_TRAINER_CLIENTS_ERROR';

export const TRYING_GET_TRAINER_CONTRACTS = 'TRYING_GET_TRAINER_CONTRACTS';
export const TRYING_GET_TRAINER_CONTRACTS_SUCCESS = 'TRYING_GET_TRAINER_CONTRACTS_SUCCESS';
export const TRYING_GET_TRAINER_CONTRACTS_ERROR = 'TRYING_GET_TRAINER_CONTRACTS_ERROR';

export const CLEAR_TRAINING_PROGRAM_DETAIL = 'CLEAR_TRAINING_PROGRAM_DETAIL';
export const TRYING_GET_TRAINING_PROGRAM_DETAIL = 'TRYING_GET_TRAINING_PROGRAM_DETAIL';
export const TRYING_GET_TRAINING_PROGRAM_DETAIL_SUCCESS = 'TRYING_GET_TRAINING_PROGRAM_DETAIL_SUCCESS';
export const TRYING_GET_TRAINING_PROGRAM_DETAIL_ERROR = 'TRYING_GET_TRAINING_PROGRAM_DETAIL_ERROR';

export const TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS = 'TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS';
export const TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS = 'TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_SUCCESS';
export const TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_ERROR = 'TRYING_GET_TRAININGPROGRAM_FILTER_SETTINGS_ERROR';

export const TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE = 'TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE';
export const TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE_SUCCESS = 'TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE_SUCCESS';
export const TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE_ERROR = 'TRYING_GET_USER_WORKOUT_MAXES_ENDURANCE_ERROR';

export const TRYING_GET_USER_WORKOUT_MAXES_STRENGTH = 'TRYING_GET_USER_WORKOUT_MAXES_STRENGTH';
export const TRYING_GET_USER_WORKOUT_MAXES_STRENGTH_SUCCESS = 'TRYING_GET_USER_WORKOUT_MAXES_STRENGTH_SUCCESS';
export const TRYING_GET_USER_WORKOUT_MAXES_STRENGTH_ERROR = 'TRYING_GET_USER_WORKOUT_MAXES_STRENGTH_ERROR';

export const CLEAR_VIEW_PRIVATE_FOOD_SETTINGS = 'CLEAR_VIEW_PRIVATE_FOOD_SETTINGS';
export const TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS = 'TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS';
export const TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS_SUCCESS = 'TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS_SUCCESS';
export const TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS_ERROR = 'TRYING_GET_VIEW_PRIVATE_FOOD_SETTINGS_ERROR';

export const TRYING_GET_VIDEO_SESSIONS = 'TRYING_GET_VIDEO_SESSIONS';
export const TRYING_GET_VIDEO_SESSIONS_SUCCESS = 'TRYING_GET_VIDEO_SESSIONS_SUCCESS';
export const TRYING_GET_VIDEO_SESSIONS_ERROR = 'TRYING_GET_VIDEO_SESSIONS_ERROR';

export const CLEAR_WORKOUT_DETAIL = 'CLEAR_WORKOUT_DETAIL';
export const TRYING_GET_WORKOUT_DETAIL = 'TRYING_GET_WORKOUT_DETAIL';
export const TRYING_GET_WORKOUT_DETAIL_SUCCESS = 'TRYING_GET_WORKOUT_DETAIL_SUCCESS';
export const TRYING_GET_WORKOUT_DETAIL_ERROR = 'TRYING_GET_WORKOUT_DETAIL_ERROR';

export const TRYING_GET_WORKOUT_NOTES = 'TRYING_GET_WORKOUT_NOTES';
export const TRYING_GET_WORKOUT_NOTES_SUCCESS = 'TRYING_GET_WORKOUT_NOTES_SUCCESS';
export const TRYING_GET_WORKOUT_NOTES_ERROR = 'TRYING_GET_WORKOUT_NOTES_ERROR';

export const TRYING_GET_WORKOUT_NOTES_BY_DATE_RANGE = 'TRYING_GET_WORKOUT_NOTES_BY_DATE_RANGE';
export const TRYING_GET_WORKOUT_NOTES_BY_DATE_RANGE_SUCCESS = 'TRYING_GET_WORKOUT_NOTES_BY_DATE_RANGE_SUCCESS';
export const TRYING_GET_WORKOUT_NOTES_BY_DATE_RANGE_ERROR = 'TRYING_GET_WORKOUT_NOTES_BY_DATE_RANGE_ERROR';

export const TRYING_GET_WORKOUT_PREVIEW = 'TRYING_GET_WORKOUT_PREVIEW';
export const TRYING_GET_WORKOUT_PREVIEW_SUCCESS = 'TRYING_GET_WORKOUT_PREVIEW_SUCCESS';
export const TRYING_GET_WORKOUT_PREVIEW_ERROR = 'TRYING_GET_WORKOUT_PREVIEW_ERROR';

export const CLEAR_WORKOUT_TEMPLATE_DETAIL = 'CLEAR_WORKOUT_TEMPLATE_DETAIL';
export const TRYING_GET_WORKOUT_TEMPLATE_DETAIL = 'TRYING_GET_WORKOUT_TEMPLATE_DETAIL';
export const TRYING_GET_WORKOUT_TEMPLATE_DETAIL_SUCCESS = 'TRYING_GET_WORKOUT_TEMPLATE_DETAIL_SUCCESS';
export const TRYING_GET_WORKOUT_TEMPLATE_DETAIL_ERROR = 'TRYING_GET_WORKOUT_TEMPLATE_DETAIL_ERROR';

export const TRYING_GET_WORKOUT_TEMPLATE_FILTERS = 'TRYING_GET_WORKOUT_TEMPLATE_FILTERS';
export const TRYING_GET_WORKOUT_TEMPLATE_FILTERS_SUCCESS = 'TRYING_GET_WORKOUT_TEMPLATE_FILTERS_SUCCESS';
export const TRYING_GET_WORKOUT_TEMPLATE_FILTERS_ERROR = 'TRYING_GET_WORKOUT_TEMPLATE_FILTERS_ERROR';

export const TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS = 'TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS';
export const TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS = 'TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_SUCCESS';
export const TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_ERROR = 'TRYING_GET_WORKOUTTEMPLATE_FILTER_SETTINGS_ERROR';

export const TRYING_GET_WORKOUT_TEMPLATE_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS = 'TRYING_GET_WORKOUT_TEMPLATE_ADDITIONAL_ACTIVITY_DETAILS_SUCCESS';

export const TRYING_UPDATE_MEAL_ACCESS = 'TRYING_UPDATE_MEAL_ACCESS';
export const TRYING_UPDATE_MEAL_ACCESS_SUCCESS = 'TRYING_UPDATE_MEAL_ACCESS_SUCCESS';
export const TRYING_UPDATE_MEAL_ACCESS_ERROR = 'TRYING_UPDATE_MEAL_ACCESS_ERROR';

export const TRYING_UPDATE_WORKOUT_ACCESS = 'TRYING_UPDATE_WORKOUT_ACCESS';
export const TRYING_UPDATE_WORKOUT_ACCESS_SUCCESS = 'TRYING_UPDATE_WORKOUT_ACCESS_SUCCESS';
export const TRYING_UPDATE_WORKOUT_ACCESS_ERROR = 'TRYING_UPDATE_WORKOUT_ACCESS_ERROR';

export const TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY = 'TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY';
export const TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY_SUCCESS = 'TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY_SUCCESS';
export const TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY_ERROR = 'TRYING_INSERTEDIT_ADDITIONAL_ACTIVITY_ERROR';

export const TRYING_INSERTEDIT_COMPANY = 'TRYING_INSERTEDIT_COMPANY';
export const TRYING_INSERTEDIT_COMPANY_SUCCESS = 'TRYING_INSERTEDIT_COMPANY_SUCCESS';
export const TRYING_INSERTEDIT_COMPANY_ERROR = 'TRYING_INSERTEDIT_COMPANY_ERROR';

export const TRYING_INSERTEDIT_ENDURANCE_GOAL = 'TRYING_INSERTEDIT_ENDURANCE_GOAL';
export const TRYING_INSERTEDIT_ENDURANCE_GOAL_SUCCESS = 'TRYING_INSERTEDIT_ENDURANCE_GOAL_SUCCESS';
export const TRYING_INSERTEDIT_ENDURANCE_GOAL_ERROR = 'TRYING_INSERTEDIT_ENDURANCE_GOAL_ERROR';

export const TRYING_INSERTEDIT_EXERCISE_GOAL = 'TRYING_INSERTEDIT_EXERCISE_GOAL';
export const TRYING_INSERTEDIT_EXERCISE_GOAL_SUCCESS = 'TRYING_INSERTEDIT_EXERCISE_GOAL_SUCCESS';
export const TRYING_INSERTEDIT_EXERCISE_GOAL_ERROR = 'TRYING_INSERTEDIT_EXERCISE_GOAL_ERROR';

export const TRYING_INSERTEDIT_EXPENSE = 'TRYING_INSERTEDIT_EXPENSE';
export const TRYING_INSERTEDIT_EXPENSE_SUCCESS = 'TRYING_INSERTEDIT_EXPENSE_SUCCESS';
export const TRYING_INSERTEDIT_EXPENSE_ERROR = 'TRYING_INSERTEDIT_EXPENSE_ERROR';

export const TRYING_INSERTEDIT_FAVORITE_ENDURANCE = 'TRYING_INSERTEDIT_FAVORITE_ENDURANCE';
export const TRYING_INSERTEDIT_FAVORITE_ENDURANCE_SUCCESS = 'TRYING_INSERTEDIT_FAVORITE_ENDURANCE_SUCCESS';
export const TRYING_INSERTEDIT_FAVORITE_ENDURANCE_ERROR = 'TRYING_INSERTEDIT_FAVORITE_ENDURANCE_ERROR';

export const TRYING_INSERTEDIT_FAVORITE_EXERCISE = 'TRYING_INSERTEDIT_FAVORITE_EXERCISE';
export const TRYING_INSERTEDIT_FAVORITE_EXERCISE_SUCCESS = 'TRYING_INSERTEDIT_FAVORITE_EXERCISE_SUCCESS';
export const TRYING_INSERTEDIT_FAVORITE_EXERCISE_ERROR = 'TRYING_INSERTEDIT_FAVORITE_EXERCISE_ERROR';

export const TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES = 'TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES';
export const TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_SUCCESS = 'TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_SUCCESS';
export const TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_ERROR = 'TRYING_INSERT_EDIT_LIBRARY_FILTER_EXERCISES_ERROR';

export const TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS = 'TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS';
export const TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_SUCCESS = 'TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_SUCCESS';
export const TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_ERROR = 'TRYING_INSERT_EDIT_LIBRARY_FILTER_TRAININGPROGRAMS_ERROR';

export const TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES = 'TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES';
export const TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_SUCCESS = 'TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_SUCCESS';
export const TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_ERROR = 'TRYING_INSERT_EDIT_LIBRARY_FILTER_WORKOUTTEMPLATES_ERROR';

export const TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER = 'TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER';
export const TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER_SUCCESS = 'TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER_SUCCESS';
export const TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER_ERROR = 'TRYING_INSERT_MEAL_PLAN_MEAL_PLAN_BUILDER_ERROR';

export const TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL = 'TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL';
export const TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL_SUCCESS = 'TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL_SUCCESS';
export const TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL_ERROR = 'TRYING_INSERTEDIT_MEAL_TEMPLATE_DETAIL_ERROR';

export const TRYING_INSERTEDIT_ORGANIZATION_BRANCH = 'TRYING_INSERTEDIT_ORGANIZATION_BRANCH';
export const TRYING_INSERTEDIT_ORGANIZATION_BRANCH_SUCCESS = 'TRYING_INSERTEDIT_ORGANIZATION_BRANCH_SUCCESS';
export const TRYING_INSERTEDIT_ORGANIZATION_BRANCH_ERROR = 'TRYING_INSERTEDIT_ORGANIZATION_BRANCH_ERROR';

export const TRYING_INSERT_EDIT_ORGANIZATION_REGION = 'TRYING_INSERT_EDIT_ORGANIZATION_REGION';
export const TRYING_INSERT_EDIT_ORGANIZATION_REGION_SUCCESS = 'TRYING_INSERT_EDIT_ORGANIZATION_REGION_SUCCESS';
export const TRYING_INSERT_EDIT_ORGANIZATION_REGION_ERROR = 'TRYING_INSERT_EDIT_ORGANIZATION_REGION_ERROR';

export const TRYING_INSERTEDIT_PRIVATE_DISH = 'TRYING_INSERTEDIT_PRIVATE_DISH';
export const TRYING_INSERTEDIT_PRIVATE_DISH_SUCCESS = 'TRYING_INSERTEDIT_PRIVATE_DISH_SUCCESS';
export const TRYING_INSERTEDIT_PRIVATE_DISH_ERROR = 'TRYING_INSERTEDIT_PRIVATE_DISH_ERROR';

export const TRYING_INSERTEDIT_PRIVATE_EXERCISE = 'TRYING_INSERTEDIT_PRIVATE_EXERCISE';
export const TRYING_INSERTEDIT_PRIVATE_EXERCISE_SUCCESS = 'TRYING_INSERTEDIT_PRIVATE_EXERCISE_SUCCESS';
export const TRYING_INSERTEDIT_PRIVATE_EXERCISE_ERROR = 'TRYING_INSERTEDIT_PRIVATE_EXERCISE_ERROR';

export const TRYING_INSERTEDIT_PRIVATE_FOOD = 'TRYING_INSERTEDIT_PRIVATE_FOOD';
export const TRYING_INSERTEDIT_PRIVATE_FOOD_SUCCESS = 'TRYING_INSERTEDIT_PRIVATE_FOOD_SUCCESS';
export const TRYING_INSERTEDIT_PRIVATE_FOOD_ERROR = 'TRYING_INSERTEDIT_PRIVATE_FOOD_ERROR';

export const TRYING_INSERTEDIT_PRODUCT = 'TRYING_INSERTEDIT_PRODUCT';
export const TRYING_INSERTEDIT_PRODUCT_SUCCESS = 'TRYING_INSERTEDIT_PRODUCT_SUCCESS';
export const TRYING_INSERTEDIT_PRODUCT_ERROR = 'TRYING_INSERTEDIT_PRODUCT_ERROR';

export const TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA = 'TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA';
export const TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA_SUCCESS = 'TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA_SUCCESS';
export const TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA_ERROR = 'TRYING_INSERTEDIT_TRAINER_CLIENT_MEDIA_ERROR';

export const TRYING_INSERTEDIT_TRAINER_GROUP = 'TRYING_INSERTEDIT_TRAINER_GROUP';
export const TRYING_INSERTEDIT_TRAINER_GROUP_SUCCESS = 'TRYING_INSERTEDIT_TRAINER_GROUP_SUCCESS';
export const TRYING_INSERTEDIT_TRAINER_GROUP_ERROR = 'TRYING_INSERTEDIT_TRAINER_GROUP_ERROR';

export const TRYING_INSERTEDIT_TRAINING_PROGRAM = 'TRYING_INSERTEDIT_TRAINING_PROGRAM';
export const TRYING_INSERTEDIT_TRAINING_PROGRAM_SUCCESS = 'TRYING_INSERTEDIT_TRAINING_PROGRAM_SUCCESS';
export const TRYING_INSERTEDIT_TRAINING_PROGRAM_ERROR = 'TRYING_INSERTEDIT_TRAINING_PROGRAM_ERROR';

export const TRYING_INSERTEDIT_USER_GROUP = 'TRYING_INSERTEDIT_USER_GROUP';
export const TRYING_INSERTEDIT_USER_GROUP_SUCCESS = 'TRYING_INSERTEDIT_USER_GROUP_SUCCESS';
export const TRYING_INSERTEDIT_USER_GROUP_ERROR = 'TRYING_INSERTEDIT_USER_GROUP_ERROR';

export const TRYING_INSERTEDIT_USER_GROUP_MEMBERS = 'TRYING_INSERTEDIT_USER_GROUP_MEMBERS';
export const TRYING_INSERTEDIT_USER_GROUP_MEMBERS_SUCCESS = 'TRYING_INSERTEDIT_USER_GROUP_MEMBERS_SUCCESS';
export const TRYING_INSERTEDIT_USER_GROUP_MEMBERS_ERROR = 'TRYING_INSERTEDIT_USER_GROUP_MEMBERS_ERROR';

export const TRYING_INSERTEDIT_WORKOUT_NOTE = 'TRYING_INSERTEDIT_WORKOUT_NOTE';
export const TRYING_INSERTEDIT_WORKOUT_NOTE_SUCCESS = 'TRYING_INSERTEDIT_WORKOUT_NOTE_SUCCESS';
export const TRYING_INSERTEDIT_WORKOUT_NOTE_ERROR = 'TRYING_INSERTEDIT_WORKOUT_NOTE_ERROR';

export const TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL = 'TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL';
export const TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL_SUCCESS = 'TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL_SUCCESS';
export const TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL_ERROR = 'TRYING_INSERTEDIT_WORKOUT_TEMPLATE_DETAIL_ERROR';

export const TRYING_INVITE_ORGANIZATION_MEMBERS = 'TRYING_INVITE_ORGANIZATION_MEMBERS';
export const TRYING_INVITE_ORGANIZATION_MEMBERS_SUCCESS = 'TRYING_INVITE_ORGANIZATION_MEMBERS_SUCCESS';
export const TRYING_INVITE_ORGANIZATION_MEMBERS_ERROR = 'TRYING_INVITE_ORGANIZATION_MEMBERS_ERROR';

export const TRYING_LOGIN_USER = 'TRYING_LOGIN_USER';
export const TRYING_LOGIN_USER_SUCCESS = 'TRYING_LOGIN_USER_SUCCESS';
export const TRYING_LOGIN_USER_ERROR = 'TRYING_LOGIN_USER_ERROR';

export const TRYING_MOVE_GROUP_WORKOUTS = 'TRYING_MOVE_GROUP_WORKOUTS';
export const TRYING_MOVE_GROUP_WORKOUTS_SUCCESS = 'TRYING_MOVE_GROUP_WORKOUTS_SUCCESS';
export const TRYING_MOVE_GROUP_WORKOUTS_ERROR = 'TRYING_MOVE_GROUP_WORKOUTS_ERROR';

export const TRYING_MOVE_MEALS = 'TRYING_MOVE_MEALS';
export const TRYING_MOVE_MEALS_SUCCESS = 'TRYING_MOVE_MEALS_SUCCESS';
export const TRYING_MOVE_MEALS_ERROR = 'TRYING_MOVE_MEALS_ERROR';

export const TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION = 'TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION';
export const TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION_SUCCESS = 'TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION_SUCCESS';
export const TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION_ERROR = 'TRYING_MOVE_ORGANIZATION_BRANCH_TO_NEW_REGION_ERROR';

export const TRYING_MOVE_WORKOUTS = 'TRYING_MOVE_WORKOUTS';
export const TRYING_MOVE_WORKOUTS_SUCCESS = 'TRYING_MOVE_WORKOUTS_SUCCESS';
export const TRYING_MOVE_WORKOUTS_ERROR = 'TRYING_MOVE_WORKOUTS_ERROR';

export const TRYING_PROCESS_STAGE_SUBSCRIBER = 'TRYING_PROCESS_STAGE_SUBSCRIBER';
export const TRYING_PROCESS_STAGE_SUBSCRIBER_SUCCESS = 'TRYING_PROCESS_STAGE_SUBSCRIBER_SUCCESS';
export const TRYING_PROCESS_STAGE_SUBSCRIBER_ERROR = 'TRYING_PROCESS_STAGE_SUBSCRIBER_ERROR';

export const CLEAR_READ_ALL_NOTIFICATIONS = 'CLEAR_READ_ALL_NOTIFICATIONS';
export const TRYING_READ_ALL_NOTIFICATIONS = 'TRYING_READ_ALL_NOTIFICATIONS';
export const TRYING_READ_ALL_NOTIFICATIONS_SUCCESS = 'TRYING_READ_ALL_NOTIFICATIONS_SUCCESS';
export const TRYING_READ_ALL_NOTIFICATIONS_ERROR = 'TRYING_READ_ALL_NOTIFICATIONS_ERROR';

export const TRYING_READ_NOTIFICATION = 'TRYING_READ_NOTIFICATION';
export const TRYING_READ_NOTIFICATION_SUCCESS = 'TRYING_READ_NOTIFICATION_SUCCESS';
export const TRYING_READ_NOTIFICATION_ERROR = 'TRYING_READ_NOTIFICATION_ERROR';

export const TRYING_REGENERATE_MAXSPEED = 'TRYING_REGENERATE_MAXSPEED';
export const TRYING_REGENERATE_MAXSPEED_SUCCESS = 'TRYING_REGENERATE_MAXSPEED_SUCCESS';
export const TRYING_REGENERATE_MAXSPEED_ERROR = 'TRYING_REGENERATE_MAXSPEED_ERROR';

export const TRYING_REGENERATE_TESTSET = 'TRYING_REGENERATE_TESTSET';
export const TRYING_REGENERATE_TESTSET_SUCCESS = 'TRYING_REGENERATE_TESTSET_SUCCESS';
export const TRYING_REGENERATE_TESTSET_ERROR = 'TRYING_REGENERATE_TESTSET_ERROR';

export const TRYING_REMOVE_FAVORITE_ENDURANCE = 'TRYING_REMOVE_FAVORITE_ENDURANCE';
export const TRYING_REMOVE_FAVORITE_ENDURANCE_SUCCESS = 'TRYING_REMOVE_FAVORITE_ENDURANCE_SUCCESS';
export const TRYING_REMOVE_FAVORITE_ENDURANCE_ERROR = 'TRYING_REMOVE_FAVORITE_ENDURANCE_ERROR';

export const TRYING_REMOVE_FAVORITE_EXERCISE = 'TRYING_REMOVE_FAVORITE_EXERCISE';
export const TRYING_REMOVE_FAVORITE_EXERCISE_SUCCESS = 'TRYING_REMOVE_FAVORITE_EXERCISE_SUCCESS';
export const TRYING_REMOVE_FAVORITE_EXERCISE_ERROR = 'TRYING_REMOVE_FAVORITE_EXERCISE_ERROR';

export const TRYING_REORDER_EXERCISES = 'TRYING_REORDER_EXERCISES';
export const TRYING_REORDER_EXERCISES_SUCCESS = 'TRYING_REORDER_EXERCISES_SUCCESS';
export const TRYING_REORDER_EXERCISES_ERROR = 'TRYING_REORDER_EXERCISES_ERROR';

export const TRYING_REORDER_TRAINER_GROUPS = 'TRYING_REORDER_TRAINER_GROUPS';
export const TRYING_REORDER_TRAINER_GROUPS_SUCCESS = 'TRYING_REORDER_TRAINER_GROUPS_SUCCESS';
export const TRYING_REORDER_TRAINER_GROUPS_ERROR = 'TRYING_REORDER_TRAINER_GROUPS_ERROR';
export const UPDATE_TRAINER_GROUP_ORDER = 'UPDATE_TRAINER_GROUP_ORDER';

export const TRYING_SAVE_ORGANIZATION_CUSTOMIZATION = 'TRYING_SAVE_ORGANIZATION_CUSTOMIZATION';
export const TRYING_SAVE_ORGANIZATION_CUSTOMIZATION_SUCCESS = 'TRYING_SAVE_ORGANIZATION_CUSTOMIZATION_SUCCESS';
export const TRYING_SAVE_ORGANIZATION_CUSTOMIZATION_ERROR = 'TRYING_SAVE_ORGANIZATION_CUSTOMIZATION_ERROR';

export const CLEAR_SEARCH_CURRENCIES = 'CLEAR_SEARCH_CURRENCIES';
export const TRYING_SEARCH_CURRENCIES = 'TRYING_SEARCH_CURRENCIES';
export const TRYING_SEARCH_CURRENCIES_SUCCESS = 'TRYING_SEARCH_CURRENCIES_SUCCESS';
export const TRYING_SEARCH_CURRENCIES_ERROR = 'TRYING_SEARCH_CURRENCIES_ERROR';

export const CLEAR_SEARCH_DISHES = 'CLEAR_SEARCH_DISHES';
export const TRYING_SEARCH_DISHES = 'TRYING_SEARCH_DISHES';
export const TRYING_SEARCH_DISHES_SUCCESS = 'TRYING_SEARCH_DISHES_SUCCESS';
export const TRYING_SEARCH_DISHES_ERROR = 'TRYING_SEARCH_DISHES_ERROR';

export const CLEAR_SEARCH_EXERCISES = 'CLEAR_SEARCH_EXERCISES';
export const TRYING_SEARCH_EXERCISES = 'TRYING_SEARCH_EXERCISES';
export const TRYING_SEARCH_EXERCISES_SUCCESS = 'TRYING_SEARCH_EXERCISES_SUCCESS';
export const TRYING_SEARCH_EXERCISES_ERROR = 'TRYING_SEARCH_EXERCISES_ERROR';

export const CLEAR_SEARCH_EXPENSES = 'CLEAR_SEARCH_EXPENSES';
export const TRYING_SEARCH_EXPENSES = 'TRYING_SEARCH_EXPENSES';
export const TRYING_SEARCH_EXPENSES_SUCCESS = 'TRYING_SEARCH_EXPENSES_SUCCESS';
export const TRYING_SEARCH_EXPENSES_ERROR = 'TRYING_SEARCH_EXPENSES_ERROR';

export const CLEAR_SEARCH_FOOD = 'CLEAR_SEARCH_FOOD';
export const TRYING_SEARCH_FOOD = 'TRYING_SEARCH_FOOD';
export const TRYING_SEARCH_FOOD_SUCCESS = 'TRYING_SEARCH_FOOD_SUCCESS';
export const TRYING_SEARCH_FOOD_ERROR = 'TRYING_SEARCH_FOOD_ERROR';

export const TRYING_SEARCH_GROUP_MANAGERS = 'TRYING_SEARCH_GROUP_MANAGERS';
export const TRYING_SEARCH_GROUP_MANAGERS_SUCCESS = 'TRYING_SEARCH_GROUP_MANAGERS_SUCCESS';
export const TRYING_SEARCH_GROUP_MANAGERS_ERROR = 'TRYING_SEARCH_GROUP_MANAGERS_ERROR';

export const TRYING_SEARCH_GROUP_MEMBERS = 'TRYING_SEARCH_GROUP_MEMBERS';
export const TRYING_SEARCH_GROUP_MEMBERS_SUCCESS = 'TRYING_SEARCH_GROUP_MEMBERS_SUCCESS';
export const TRYING_SEARCH_GROUP_MEMBERS_ERROR = 'TRYING_SEARCH_GROUP_MEMBERS_ERROR';

export const TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS = 'TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS';
export const TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS_SUCCESS = 'TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS_SUCCESS';
export const TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS_ERROR = 'TRYING_SEARCH_GROUP_MEMBER_WORKOUT_COMPLETION_STATUS_ERROR';

export const CLEAR_SEARCH_GROUPS = 'CLEAR_SEARCH_GROUPS';
export const TRYING_SEARCH_GROUPS = 'TRYING_SEARCH_GROUPS';
export const TRYING_SEARCH_GROUPS_SUCCESS = 'TRYING_SEARCH_GROUPS_SUCCESS';
export const TRYING_SEARCH_GROUPS_ERROR = 'TRYING_SEARCH_GROUPS_ERROR';

export const CLEAR_SEARCH_MEAL_TEMPLATES = 'CLEAR_SEARCH_MEAL_TEMPLATES';
export const TRYING_SEARCH_MEAL_TEMPLATES = 'TRYING_SEARCH_MEAL_TEMPLATES';
export const TRYING_SEARCH_MEAL_TEMPLATES_SUCCESS = 'TRYING_SEARCH_MEAL_TEMPLATES_SUCCESS';
export const TRYING_SEARCH_MEAL_TEMPLATES_ERROR = 'TRYING_SEARCH_MEAL_TEMPLATES_ERROR';

export const TRYING_SEARCH_PAYOUTS = 'TRYING_SEARCH_PAYOUTS';
export const TRYING_SEARCH_PAYOUTS_SUCCESS = 'TRYING_SEARCH_PAYOUTS_SUCCESS';
export const TRYING_SEARCH_PAYOUTS_ERROR = 'TRYING_SEARCH_PAYOUTS_ERROR';

export const TRYING_SEARCH_PRODUCTS = 'TRYING_SEARCH_PRODUCTS';
export const TRYING_SEARCH_PRODUCTS_SUCCESS = 'TRYING_SEARCH_PRODUCTS_SUCCESS';
export const TRYING_SEARCH_PRODUCTS_ERROR = 'TRYING_SEARCH_PRODUCTS_ERROR';

export const TRYING_SEARCH_TRAINER_CLIENTS = 'TRYING_SEARCH_TRAINER_CLIENTS';
export const TRYING_SEARCH_TRAINER_CLIENTS_SUCCESS = 'TRYING_SEARCH_TRAINER_CLIENTS_SUCCESS';
export const TRYING_SEARCH_TRAINER_CLIENTS_ERROR = 'TRYING_SEARCH_TRAINER_CLIENTS_ERROR';

export const TRYING_SEARCH_TRAINER_CLIENTS_SAYT = 'TRYING_SEARCH_TRAINER_CLIENTS_SAYT';
export const TRYING_SEARCH_TRAINER_CLIENTS_SAYT_SUCCESS = 'TRYING_SEARCH_TRAINER_CLIENTS_SAYT_SUCCESS';
export const TRYING_SEARCH_TRAINER_CLIENTS_SAYT_ERROR = 'TRYING_SEARCH_TRAINER_CLIENTS_SAYT_ERROR';

export const TRYING_SEARCH_TRAINER_CONTRACTS = 'TRYING_SEARCH_TRAINER_CONTRACTS';
export const TRYING_SEARCH_TRAINER_CONTRACTS_SUCCESS = 'TRYING_SEARCH_TRAINER_CONTRACTS_SUCCESS';
export const TRYING_SEARCH_TRAINER_CONTRACTS_ERROR = 'TRYING_SEARCH_TRAINER_CONTRACTS_ERROR';

export const TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS = 'TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS';
export const TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_SUCCESS = 'TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_SUCCESS';
export const TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_ERROR = 'TRYING_SEARCH_TRAINER_CONTRACTS_CLIENTS_ERROR';

export const CLEAR_SEARCH_TRAINING_PROGRAMS = 'CLEAR_SEARCH_TRAINING_PROGRAMS';
export const TRYING_SEARCH_TRAINING_PROGRAMS = 'TRYING_SEARCH_TRAINING_PROGRAMS';
export const TRYING_SEARCH_TRAINING_PROGRAMS_SUCCESS = 'TRYING_SEARCH_TRAINING_PROGRAMS_SUCCESS';
export const TRYING_SEARCH_TRAINING_PROGRAMS_ERROR = 'TRYING_SEARCH_TRAINING_PROGRAMS_ERROR';

export const CLEAR_SEARCH_WORKOUT_TEMPLATES = 'CLEAR_SEARCH_WORKOUT_TEMPLATES';
export const TRYING_SEARCH_WORKOUT_TEMPLATES = 'TRYING_SEARCH_WORKOUT_TEMPLATES';
export const TRYING_SEARCH_WORKOUT_TEMPLATES_SUCCESS = 'TRYING_SEARCH_WORKOUT_TEMPLATES_SUCCESS';
export const TRYING_SEARCH_WORKOUT_TEMPLATES_ERROR = 'TRYING_SEARCH_WORKOUT_TEMPLATES_ERROR';

export const TRYING_SUBSCRIBE_PAYMENT_GATEWAY = 'TRYING_SUBSCRIBE_PAYMENT_GATEWAY';
export const TRYING_SUBSCRIBE_PAYMENT_GATEWAY_SUCCESS = 'TRYING_SUBSCRIBE_PAYMENT_GATEWAY_SUCCESS';
export const TRYING_SUBSCRIBE_PAYMENT_GATEWAY_ERROR = 'TRYING_SUBSCRIBE_PAYMENT_GATEWAY_ERROR';

export const TRYING_TASKS_ACTION = 'TRYING_TASKS_ACTION';
export const TRYING_TASKS_ACTION_SUCCESS = 'TRYING_TASKS_ACTION_SUCCESS';
export const TRYING_TASKS_ACTION_ERROR = 'TRYING_TASKS_ACTION_ERROR';
export const TRYING_TASKS_ACTION_COPY = 'TRYING_TASKS_ACTION_COPY';

export const TRYING_UPDATE_CLIENT_ASSIGNING = 'TRYING_UPDATE_CLIENT_ASSIGNING';
export const TRYING_UPDATE_CLIENT_ASSIGNING_SUCCESS = 'TRYING_UPDATE_CLIENT_ASSIGNING_SUCCESS';
export const TRYING_UPDATE_CLIENT_ASSIGNING_ERROR = 'TRYING_UPDATE_CLIENT_ASSIGNING_ERROR';

export const TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY = 'TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY';
export const TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY_SUCCESS = 'TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY_SUCCESS';
export const TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY_ERROR = 'TRYING_UPDATE_CONTRACT_PRODUCT_DELIVERED_QUANTITY_ERROR';

export const TRYING_UPDATE_DAILY_ACTIVITY_LEVEL = 'TRYING_UPDATE_DAILY_ACTIVITY_LEVEL';
export const TRYING_UPDATE_DAILY_ACTIVITY_LEVEL_SUCCESS = 'TRYING_UPDATE_DAILY_ACTIVITY_LEVEL_SUCCESS';
export const TRYING_UPDATE_DAILY_ACTIVITY_LEVEL_ERROR = 'TRYING_UPDATE_DAILY_ACTIVITY_LEVEL_ERROR';

export const TRYING_UPDATE_DISH_COMPLETION_STATUS = 'TRYING_UPDATE_DISH_COMPLETION_STATUS';
export const TRYING_UPDATE_DISH_COMPLETION_STATUS_SUCCESS = 'TRYING_UPDATE_DISH_COMPLETION_STATUS_SUCCESS';
export const TRYING_UPDATE_DISH_COMPLETION_STATUS_ERROR = 'TRYING_UPDATE_DISH_COMPLETION_STATUS_ERROR';

export const TRYING_UPDATE_DISH_DETAIL_FOOD = 'TRYING_UPDATE_DISH_DETAIL_FOOD';
export const TRYING_UPDATE_DISH_DETAIL_FOOD_SUCCESS = 'TRYING_UPDATE_DISH_DETAIL_FOOD_SUCCESS';
export const TRYING_UPDATE_DISH_DETAIL_FOOD_ERROR = 'TRYING_UPDATE_DISH_DETAIL_FOOD_ERROR';

export const TRYING_UPDATE_NOTIFICATION_SETTINGS = 'TRYING_UPDATE_NOTIFICATION_SETTINGS';
export const TRYING_UPDATE_NOTIFICATION_SETTINGS_SUCCESS = 'TRYING_UPDATE_NOTIFICATION_SETTINGS_SUCCESS';
export const TRYING_UPDATE_NOTIFICATION_SETTINGS_ERROR = 'TRYING_UPDATE_NOTIFICATION_SETTINGS_ERROR';

export const TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS = 'TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS';
export const TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS_SUCCESS = 'TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS_SUCCESS';
export const TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS_ERROR = 'TRYING_UPDATE_NUTRITION_PREVIEW_FILTERS_ERROR';

export const TRYING_UPDATE_ORGANIZATION_INCOME = 'TRYING_UPDATE_ORGANIZATION_INCOME';
export const TRYING_UPDATE_ORGANIZATION_INCOME_SUCCESS = 'TRYING_UPDATE_ORGANIZATION_INCOME_SUCCESS';
export const TRYING_UPDATE_ORGANIZATION_INCOME_ERROR = 'TRYING_UPDATE_ORGANIZATION_INCOME_ERROR';

export const TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS = 'TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS';
export const TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_SUCCESS = 'TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_SUCCESS';
export const TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_ERROR = 'TRYING_UPDATE_ORGANIZATION_MEMBERSHIP_STATUS_ERROR';

export const TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS = 'TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS';
export const TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS_SUCCESS = 'TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS_SUCCESS';
export const TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS_ERROR = 'TRYING_UPDATE_ORGANIZATION_MEMBER_DETAILS_ERROR';

export const TRYING_UPDATE_PAUSED_SHARING_EXERCISES = 'TRYING_UPDATE_PAUSED_SHARING_EXERCISES';
export const TRYING_UPDATE_PAUSED_SHARING_EXERCISES_SUCCESS = 'TRYING_UPDATE_PAUSED_SHARING_EXERCISES_SUCCESS';
export const TRYING_UPDATE_PAUSED_SHARING_EXERCISES_ERROR = 'TRYING_UPDATE_PAUSED_SHARING_EXERCISES_ERROR';

export const TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES = 'TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES';
export const TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES_SUCCESS = 'TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES_SUCCESS';
export const TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES_ERROR = 'TRYING_UPDATE_PAUSED_SHARING_MEALTEMPLATES_ERROR';

export const TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS = 'TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS';
export const TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS_SUCCESS = 'TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS_SUCCESS';
export const TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS_ERROR = 'TRYING_UPDATE_PAUSED_SHARING_TRAININGPROGRAMS_ERROR';

export const TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES = 'TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES';
export const TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES_SUCCESS = 'TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES_SUCCESS';
export const TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES_ERROR = 'TRYING_UPDATE_PAUSED_SHARING_WORKOUTTEMPLATES_ERROR';

export const TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES = 'TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES';
export const TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_SUCCESS = 'TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_SUCCESS';
export const TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_ERROR = 'TRYING_UPDATE_SHAREABILITY_STATUS_EXERCISES_ERROR';

export const TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES = 'TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES';
export const TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES_SUCCESS = 'TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES_SUCCESS';
export const TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES_ERROR = 'TRYING_UPDATE_SHAREABILITY_STATUS_MEALTEMPLATES_ERROR';

export const TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS = 'TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS';
export const TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS_SUCCESS = 'TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS_SUCCESS';
export const TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS_ERROR = 'TRYING_UPDATE_SHAREABILITY_STATUS_TRAININGPROGRAMS_ERROR';

export const TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES = 'TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES';
export const TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES_SUCCESS = 'TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES_SUCCESS';
export const TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES_ERROR = 'TRYING_UPDATE_SHAREABILITY_STATUS_WORKOUTTEMPLATES_ERROR';

export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES_SUCCESS = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES_SUCCESS';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES_ERROR = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_EXERCISES_ERROR';

export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES_SUCCESS = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES_SUCCESS';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES_ERROR = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_MEALTEMPLATES_ERROR';

export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS_SUCCESS = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS_SUCCESS';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS_ERROR = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_TRAININGPROGRAMS_ERROR';

export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES_SUCCESS = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES_SUCCESS';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES_ERROR = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_ALL_WORKOUTTEMPLATES_ERROR';

export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES_SUCCESS = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES_SUCCESS';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES_ERROR = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_EXERCISES_ERROR';

export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES_SUCCESS = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES_SUCCESS';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES_ERROR = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_MEALTEMPLATES_ERROR';

export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS_SUCCESS = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS_SUCCESS';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS_ERROR = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_TRAININGPROGRAMS_ERROR';

export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES_SUCCESS = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES_SUCCESS';
export const TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES_ERROR = 'TRYING_UPDATE_SHARED_TO_COACH_STATUS_WORKOUTTEMPLATES_ERROR';

export const TRYING_UPDATE_SUBSCRIPTION_STATUS = 'TRYING_UPDATE_SUBSCRIPTION_STATUS';

export const TRYING_UPDATE_TRAINER_CLIENT_COLOR = 'TRYING_UPDATE_TRAINER_CLIENT_COLOR';
export const TRYING_UPDATE_TRAINER_CLIENT_COLOR_SUCCESS = 'TRYING_UPDATE_TRAINER_CLIENT_COLOR_SUCCESS';
export const TRYING_UPDATE_TRAINER_CLIENT_COLOR_ERROR = 'TRYING_UPDATE_TRAINER_CLIENT_COLOR_ERROR';

export const TRYING_UPDATE_TRAINER_CLIENT_NOTE = 'TRYING_UPDATE_TRAINER_CLIENT_NOTE';
export const TRYING_UPDATE_TRAINER_CLIENT_NOTE_SUCCESS = 'TRYING_UPDATE_TRAINER_CLIENT_NOTE_SUCCESS';
export const TRYING_UPDATE_TRAINER_CLIENT_NOTE_ERROR = 'TRYING_UPDATE_TRAINER_CLIENT_NOTE_ERROR';

export const TRYING_UPDATE_USER_SETTINGS = 'TRYING_UPDATE_USER_SETTINGS';
export const TRYING_UPDATE_USER_SETTINGS_SUCCESS = 'TRYING_UPDATE_USER_SETTINGS_SUCCESS';
export const TRYING_UPDATE_USER_SETTINGS_ERROR = 'TRYING_UPDATE_USER_SETTINGS_ERROR';

export const TRYING_UPDATE_WORKOUT_INFORMATION = 'TRYING_UPDATE_WORKOUT_INFORMATION';
export const TRYING_UPDATE_WORKOUT_INFORMATION_SUCCESS = 'TRYING_UPDATE_WORKOUT_INFORMATION_SUCCESS';
export const TRYING_UPDATE_WORKOUT_INFORMATION_ERROR = 'TRYING_UPDATE_WORKOUT_INFORMATION_ERROR';

export const TRYING_UPDATE_WORKOUT_LAP = 'TRYING_UPDATE_WORKOUT_LAP';
export const TRYING_UPDATE_WORKOUT_LAP_SUCCESS = 'TRYING_UPDATE_WORKOUT_LAP_SUCCESS';
export const TRYING_UPDATE_WORKOUT_LAP_ERROR = 'TRYING_UPDATE_WORKOUT_LAP_ERROR';

export const TRYING_UPDATE_WORKOUT_SET = 'TRYING_UPDATE_WORKOUT_SET';
export const TRYING_UPDATE_WORKOUT_SET_SUCCESS = 'TRYING_UPDATE_WORKOUT_SET_SUCCESS';
export const TRYING_UPDATE_WORKOUT_SET_ERROR = 'TRYING_UPDATE_WORKOUT_SET_ERROR';

export const TRYING_UPLOAD_ORGANIZATION_PICTURE = 'TRYING_UPLOAD_ORGANIZATION_PICTURE';
export const TRYING_UPLOAD_ORGANIZATION_PICTURE_SUCCESS = 'TRYING_UPLOAD_ORGANIZATION_PICTURE_SUCCESS';
export const TRYING_UPLOAD_ORGANIZATION_PICTURE_ERROR = 'TRYING_UPLOAD_ORGANIZATION_PICTURE_ERROR';

export const TRYING_UPLOAD_PROFILE_PICTURE = 'TRYING_UPLOAD_PROFILE_PICTURE';
export const TRYING_UPLOAD_PROFILE_PICTURE_SUCCESS = 'TRYING_UPLOAD_PROFILE_PICTURE_SUCCESS';
export const TRYING_UPLOAD_PROFILE_PICTURE_ERROR = 'TRYING_UPLOAD_PROFILE_PICTURE_ERROR';

export const TRYING_USER_ACCOUNTS_ACTION = 'TRYING_USER_ACCOUNTS_ACTION';
export const TRYING_USER_ACCOUNTS_ACTION_SUCCESS = 'TRYING_USER_ACCOUNTS_ACTION_SUCCESS';
export const TRYING_USER_ACCOUNTS_ACTION_ERROR = 'TRYING_USER_ACCOUNTS_ACTION_ERROR';

export const TRYING_VALIDATE_USER = 'TRYING_VALIDATE_USER';
export const TRYING_VALIDATE_USER_SUCCESS = 'TRYING_VALIDATE_USER_SUCCESS';
export const TRYING_VALIDATE_USER_ERROR = 'TRYING_VALIDATE_USER_ERROR';

export const TRYING_VIEW_AVAILABLE_LANGUAGES = 'TRYING_VIEW_AVAILABLE_LANGUAGES';
export const TRYING_VIEW_AVAILABLE_LANGUAGES_SUCCESS = 'TRYING_VIEW_AVAILABLE_LANGUAGES_SUCCESS';
export const TRYING_VIEW_AVAILABLE_LANGUAGES_ERROR = 'TRYING_VIEW_AVAILABLE_LANGUAGES_ERROR';

export const CLEAR_VIEW_BODYGOAL_PROGRESS = 'CLEAR_VIEW_BODYGOAL_PROGRESS';
export const TRYING_VIEW_BODYGOAL_PROGRESS = 'TRYING_VIEW_BODYGOAL_PROGRESS';
export const TRYING_VIEW_BODYGOAL_PROGRESS_SUCCESS = 'TRYING_VIEW_BODYGOAL_PROGRESS_SUCCESS';
export const TRYING_VIEW_BODYGOAL_PROGRESS_ERROR = 'TRYING_VIEW_BODYGOAL_PROGRESS_ERROR';

export const TRYING_VIEW_BODYGOAL_PROGRESS_CHART = 'TRYING_VIEW_BODYGOAL_PROGRESS_CHART';
export const TRYING_VIEW_BODYGOAL_PROGRESS_CHART_SUCCESS = 'TRYING_VIEW_BODYGOAL_PROGRESS_CHART_SUCCESS';
export const TRYING_VIEW_BODYGOAL_PROGRESS_CHART_ERROR = 'TRYING_VIEW_BODYGOAL_PROGRESS_CHART_ERROR';

export const TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER = 'TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER';
export const TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER_SUCCESS = 'TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER_SUCCESS';
export const TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER_ERROR = 'TRYING_VIEW_DISH_FOODS_MEAL_PLAN_BUILDER_ERROR';

export const CLEAR_VIEW_ENDURANCE_PROGRESS = 'CLEAR_VIEW_ENDURANCE_PROGRESS';
export const TRYING_VIEW_ENDURANCE_PROGRESS = 'TRYING_VIEW_ENDURANCE_PROGRESS';
export const TRYING_VIEW_ENDURANCE_PROGRESS_SUCCESS = 'TRYING_VIEW_ENDURANCE_PROGRESS_SUCCESS';
export const TRYING_VIEW_ENDURANCE_PROGRESS_ERROR = 'TRYING_VIEW_ENDURANCE_PROGRESS_ERROR';

export const TRYING_VIEW_ENDURANCE_TEMPLATES = 'TRYING_VIEW_ENDURANCE_TEMPLATES';
export const TRYING_VIEW_ENDURANCE_TEMPLATES_SUCCESS = 'TRYING_VIEW_ENDURANCE_TEMPLATES_SUCCESS';
export const TRYING_VIEW_ENDURANCE_TEMPLATES_ERROR = 'TRYING_VIEW_ENDURANCE_TEMPLATES_ERROR';

export const TRYING_VIEW_EXPENSE_CATEGORIES = 'TRYING_VIEW_EXPENSE_CATEGORIES';
export const TRYING_VIEW_EXPENSE_CATEGORIES_SUCCESS = 'TRYING_VIEW_EXPENSE_CATEGORIES_SUCCESS';
export const TRYING_VIEW_EXPENSE_CATEGORIES_ERROR = 'TRYING_VIEW_EXPENSE_CATEGORIES_ERROR';

export const TRYING_VIEW_FOOD_UNITS = 'TRYING_VIEW_FOOD_UNITS';
export const TRYING_VIEW_FOOD_UNITS_SUCCESS = 'TRYING_VIEW_FOOD_UNITS_SUCCESS';
export const TRYING_VIEW_FOOD_UNITS_ERROR = 'TRYING_VIEW_FOOD_UNITS_ERROR';

export const TRYING_VIEW_NOTIFICATION_HISTORY = 'TRYING_VIEW_NOTIFICATION_HISTORY';
export const TRYING_VIEW_NOTIFICATION_HISTORY_SUCCESS = 'TRYING_VIEW_NOTIFICATION_HISTORY_SUCCESS';
export const TRYING_VIEW_NOTIFICATION_HISTORY_ERROR = 'TRYING_VIEW_NOTIFICATION_HISTORY_ERROR';

export const CLEAR_NOTIFICATION_SETTINGS = 'CLEAR_NOTIFICATION_SETTINGS';
export const TRYING_VIEW_NOTIFICATION_SETTINGS = 'TRYING_VIEW_NOTIFICATION_SETTINGS';
export const TRYING_VIEW_NOTIFICATION_SETTINGS_SUCCESS = 'TRYING_VIEW_NOTIFICATION_SETTINGS_SUCCESS';
export const TRYING_VIEW_NOTIFICATION_SETTINGS_ERROR = 'TRYING_VIEW_NOTIFICATION_SETTINGS_ERROR';

export const TRYING_VIEW_NUTRITION_PROGRESS = 'TRYING_VIEW_NUTRITION_PROGRESS';
export const TRYING_VIEW_NUTRITION_PROGRESS_SUCCESS = 'TRYING_VIEW_NUTRITION_PROGRESS_SUCCESS';
export const TRYING_VIEW_NUTRITION_PROGRESS_ERROR = 'TRYING_VIEW_NUTRITION_PROGRESS_ERROR';

export const CLEAR_NUTRITION_PREVIEW_FILTERS = 'CLEAR_NUTRITION_PREVIEW_FILTERS';
export const TRYING_VIEW_NUTRITION_PREVIEW_FILTERS = 'TRYING_VIEW_NUTRITION_PREVIEW_FILTERS';
export const TRYING_VIEW_NUTRITION_PREVIEW_FILTERS_SUCCESS = 'TRYING_VIEW_NUTRITION_PREVIEW_FILTERS_SUCCESS';
export const TRYING_VIEW_NUTRITION_PREVIEW_FILTERS_ERROR = 'TRYING_VIEW_NUTRITION_PREVIEW_FILTERS_ERROR';

export const TRYING_VIEW_ORGANIZATION_BRANCH = 'TRYING_VIEW_ORGANIZATION_BRANCH';
export const TRYING_VIEW_ORGANIZATION_BRANCH_SUCCESS = 'TRYING_VIEW_ORGANIZATION_BRANCH_SUCCESS';
export const TRYING_VIEW_ORGANIZATION_BRANCH_ERROR = 'TRYING_VIEW_ORGANIZATION_BRANCH_ERROR';

export const CLEAR_ORGANIZATION_DASHBOARD = 'CLEAR_ORGANIZATION_DASHBOARD';
export const TRYING_VIEW_ORGANIZATION_DASHBOARD = 'TRYING_VIEW_ORGANIZATION_DASHBOARD';
export const TRYING_VIEW_ORGANIZATION_DASHBOARD_SUCCESS = 'TRYING_VIEW_ORGANIZATION_DASHBOARD_SUCCESS';
export const TRYING_VIEW_ORGANIZATION_DASHBOARD_ERROR = 'TRYING_VIEW_ORGANIZATION_DASHBOARD_ERROR';

export const TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES = 'TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES';
export const TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_SUCCESS = 'TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_SUCCESS';
export const TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_ERROR = 'TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_ERROR';

export const TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS = 'TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS';
export const TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS_SUCCESS = 'TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS_SUCCESS';
export const TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS_ERROR = 'TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACT_INVOICES_FILTERS_ERROR';

export const TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS = 'TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS';
export const TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_SUCCESS = 'TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_SUCCESS';
export const TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_ERROR = 'TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_ERROR';

export const TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS = 'TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS';
export const TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS_SUCCESS = 'TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS_SUCCESS';
export const TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS_ERROR = 'TRYING_VIEW_ORGANIZATION_MEMBER_CONTRACTS_FILTERS_ERROR';

export const TRYING_VIEW_ORGANIZATION_MEMBERS = 'TRYING_VIEW_ORGANIZATION_MEMBERS';
export const TRYING_VIEW_ORGANIZATION_MEMBERS_SUCCESS = 'TRYING_VIEW_ORGANIZATION_MEMBERS_SUCCESS';
export const TRYING_VIEW_ORGANIZATION_MEMBERS_ERROR = 'TRYING_VIEW_ORGANIZATION_MEMBERS_ERROR';

export const CLEAR_VIEW_STRENGTH_PROGRESS = 'CLEAR_VIEW_STRENGTH_PROGRESS';
export const TRYING_VIEW_STRENGTH_PROGRESS = 'TRYING_VIEW_STRENGTH_PROGRESS';
export const TRYING_VIEW_STRENGTH_PROGRESS_SUCCESS = 'TRYING_VIEW_STRENGTH_PROGRESS_SUCCESS';
export const TRYING_VIEW_STRENGTH_PROGRESS_ERROR = 'TRYING_VIEW_STRENGTH_PROGRESS_ERROR';

export const TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD = 'TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD';
export const TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_SUCCESS = 'TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_SUCCESS';
export const TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_ERROR = 'TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_ERROR';

export const TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY = 'TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY';
export const TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY_SUCCESS = 'TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY_SUCCESS';
export const TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY_ERROR = 'TRYING_VIEW_TRAINER_PAYMENT_DASHBOARD_MONTHLY_ERROR';

export const TRYING_VIEW_TRAINER_PAYOUT_DETAIL = 'TRYING_VIEW_TRAINER_PAYOUT_DETAIL';
export const TRYING_VIEW_TRAINER_PAYOUT_DETAIL_SUCCESS = 'TRYING_VIEW_TRAINER_PAYOUT_DETAIL_SUCCESS';
export const TRYING_VIEW_TRAINER_PAYOUT_DETAIL_ERROR = 'TRYING_VIEW_TRAINER_PAYOUT_DETAIL_ERROR';

export const CLEAR_TRAINER_PAYOUT_SUMMARY = 'CLEAR_TRAINER_PAYOUT_SUMMARY';
export const TRYING_VIEW_TRAINER_PAYOUT_SUMMARY = 'TRYING_VIEW_TRAINER_PAYOUT_SUMMARY';
export const TRYING_VIEW_TRAINER_PAYOUT_SUMMARY_SUCCESS = 'TRYING_VIEW_TRAINER_PAYOUT_SUMMARY_SUCCESS';
export const TRYING_VIEW_TRAINER_PAYOUT_SUMMARY_ERROR = 'TRYING_VIEW_TRAINER_PAYOUT_SUMMARY_ERROR';

export const TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT = 'TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT';
export const TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT_SUCCESS = 'TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT_SUCCESS';
export const TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT_ERROR = 'TRYING_VIEW_UNREAD_NOTIFICATIONS_COUNT_ERROR';

export const REMOVE_PENDING_CONTENT_REQUEST = 'REMOVE_PENDING_CONTENT_REQUEST';

export const UPDATE_SELECTED_TAB = 'UPDATE_SELECTED_TAB';
export const UPDATE_UNREAD_NOTIFICATION_COUNT = 'UPDATE_UNREAD_NOTIFICATION_COUNT';
export const UPDATE_PDF_LOGO = 'UPDATE_PDF_LOGO';

export const TRYING_GET_UPLOAD_CONTENT = 'TRYING_GET_UPLOAD_CONTENT';
export const TRYING_GET_UPLOAD_CONTENT_SUCCESS = 'TRYING_GET_UPLOAD_CONTENT_SUCCESS';
export const TRYING_GET_UPLOAD_CONTENT_ERROR = 'TRYING_GET_UPLOAD_CONTENT_ERROR';

export const TRYING_UPLOAD_CONTENT = 'TRYING_UPLOAD_CONTENT';
export const TRYING_UPLOAD_CONTENT_SUCCESS = 'TRYING_UPLOAD_CONTENT_SUCCESS';
export const TRYING_UPLOAD_CONTENT_ERROR = 'TRYING_UPLOAD_CONTENT_ERROR';
export const TRYING_UPLOAD_CONTENT_PROGRESS = 'TRYING_UPLOAD_CONTENT_PROGRESS';