import styled from 'styled-components';

export const BodyContainer = styled.div`
    grid-area: body;
    padding: 15px;
`;

export const ProductTable = styled.div``;

export const ProductTableBody = styled.div``;

export const ProductTableBodyColumn = styled.div``;

export const ProductTableBodyRow = styled.div``;

export const ProductTableHeader = styled.div``;

export const ProductTableHeaderColumn = styled.div``;